import * as d3 from "d3";
import { useContext, useRef, useState, useEffect } from "react";
import { GlobalContextProps, GlobalContext } from "../../../globalProvider";

export interface GaugeBoxProps {
    maxVal: number;
    val: number;
    std?: number
}

const GaugeBox: React.FC<GaugeBoxProps> = (data) => {

    const d3Container = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        let isMounted = true;
        if (d3Container.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    window.requestAnimationFrame(() => {
                        if (isMounted) {
                            setContainerWidth(entry.contentRect.width);
                            setContainerHeight(entry.contentRect.height);
                        }
                    });

                }
            });

            resizeObserver.observe(d3Container.current);
            return () => {
                isMounted = false;
                resizeObserver.disconnect();
            };
        }
    }, [d3Container]);

    useEffect(() => {
        if (containerWidth > 0 && containerHeight > 0) {

            d3.select(d3Container.current).selectAll("*").remove();

            const svg = d3.select(d3Container.current).append('svg')
                .attr('width', containerWidth)
                .attr('height', containerHeight);

            if (data.maxVal < 0) return;
            
            //Filter

            const defs = svg.append("defs")
            const filter = defs.append("filter")
                .attr("id", "shadowBack")
                .attr("filterUnits", "userSpaceOnUse")

            filter.append("feOffset")
                .attr("dy", "5")
                .attr("input", "SourceAlpha")

            filter.append("feGaussianBlur")
                .attr("stdDeviation", "8")
                .attr("result", "blur")

            filter.append("feFlood")
                .attr("flood-opacity", "0.314")
                .attr("result", "color")

            filter.append("feComposite")
                .attr("operator", "out")
                .attr("in", "SourceGraphic")
                .attr("in2", "blur")

            filter.append("feComposite")
                .attr("operator", "in")
                .attr("in", "color")

            filter.append("feComposite")
                .attr("operator", "in")
                .attr("in2", "SourceGraphic")

            const g = svg.append("g")
                .attr("transform", `translate(${containerWidth / 2},${containerHeight - 3} )`)
                .attr("class", "arcSVG")

            const outerRad = Math.min(containerWidth / 2, containerHeight) - 50;
            const innerRad = outerRad / 1.5;

            // Background

            const arcGenerator = d3.arc()
                .innerRadius(innerRad)
                .outerRadius(outerRad)
                .startAngle(-Math.PI / 2)

            const scale = d3.scaleLinear()
                .domain([0, data.maxVal])
                .range([-Math.PI / 2, Math.PI / 2]);

            g.append('path')
                .datum({ endAngle: scale(data.maxVal) })
                .attr('class', 'arcBackground')
                .attr('d', arcGenerator as any);

            const tickLength = 10;
            const labelOffset = 25;

            g.selectAll('.tick')
                .data(scale.ticks(10))
                .enter().append('line')
                .attr('class', 'tick')
                .attr('x1', d => Math.cos(scale(d) - Math.PI / 2) * outerRad)
                .attr('y1', d => Math.sin(scale(d) - Math.PI / 2) * outerRad)
                .attr('x2', d => Math.cos(scale(d) - Math.PI / 2) * (outerRad + tickLength))
                .attr('y2', d => Math.sin(scale(d) - Math.PI / 2) * (outerRad + tickLength))

            g.selectAll('.label')
                .data(scale.ticks(10))
                .enter().append('text')
                .attr('class', 'label')
                .attr('x', d => Math.cos(scale(d) - Math.PI / 2) * (outerRad + labelOffset))
                .attr('y', d => Math.sin(scale(d) - Math.PI / 2) * (outerRad + labelOffset))
                .text(d => d);

            //value

            const op = g.append('path')
                .datum({ endAngle: scale(data.val) })
                .attr('class', 'arcValue')
                .attr('d', arcGenerator as any)

            if (data.val > 0) {

                op.transition()
                    .duration(1000)
                    .attrTween('d', function (d) {

                        const interpolate = d3.interpolate(scale(0), scale(data.val));
                        return function (t) {
                            return arcGenerator({ endAngle: interpolate(t) } as any) ?? "";
                        };
                    })
            }

            if (data.std) {

                //std extra
                const stdarcGenerator = d3.arc()
                    .innerRadius(innerRad + 10)
                    .outerRadius(outerRad - 10)

                const std = data.std;

                const p = g.append('path')
                    .datum({
                        startAngle: scale(data.val - std),
                        endAngle: scale(data.val + std)
                    })
                    .attr('class', 'arcStdValue')
                    .attr('d', stdarcGenerator as any)

                if (data.val > 0) {

                    p.transition()
                        .duration(1000)
                        .attrTween('d', function (d) {

                            const interpolate = d3.interpolate(0, data.val);
                            return function (t) {
                                return stdarcGenerator({
                                    startAngle: scale(interpolate(t) - std),
                                    endAngle: scale(interpolate(t) + std)
                                } as any) ?? "";
                            };
                        })
                }
            }
        }
    }, [containerWidth, containerHeight]);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    let dv = data.val;

    if (!dv) {
        dv = 0;
    }

    const tVal = Math.round(dv * 100) / 100 ?? 0;


    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            className='DGGaugeContainer'
        >
            <div className="DGALLGaugeBack" ref={d3Container}></div>
            <div className="DGALLGaugeCenterText" >{tVal}</div>
            <div className="DGALLGaugeCenterTextLabel">Reaktionsfaktor</div>
        </div>
    )
}

export default GaugeBox;