import React, { useContext, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { GlobalContext, GlobalContextProps } from '../../../globalProvider';
import { taskInfoType } from '../../../dataStruct/DGUnit';

type TicketBarProps = {
    maxTicket: number;
    tickets: taskInfoType;
}


const TicketBar: React.FC<TicketBarProps> = ({ tickets, maxTicket }) => {

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { selectedDisplay, lastUpdate } = newLocal;

    const d3Container = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        let isMounted = true;
        if (d3Container.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    window.requestAnimationFrame(() => {
                        if (isMounted) {
                            setContainerWidth(entry.contentRect.width);
                            setContainerHeight(entry.contentRect.height);
                        }
                    });

                }
            });

            resizeObserver.observe(d3Container.current);
            return () => {
                isMounted = false;
                resizeObserver.disconnect();
            };
        }
    }, [d3Container]);

    useEffect(() => {
        if (containerWidth > 0 && containerHeight > 0) {
            const data: taskInfoType = tickets;
            const maxValue = maxTicket;

            const xScale = d3.scaleLinear()
                .domain([0, maxValue])
                .range([0, containerWidth]);

            d3.select(d3Container.current).selectAll("*").remove();

            const svg = d3.select(d3Container.current).append('svg')
                .attr('width', containerWidth)
                .attr('height', containerHeight);


            let cumulativeWidth = 0;

            svg.selectAll('rect')
                .data([data.full, data.part, data.assist, data.not])
                .enter().append('rect')
                .attr('x', d => {
                    const x = cumulativeWidth;
                    cumulativeWidth += xScale(d) || 0;
                    return x;
                })
                .attr('y', 0) // Konstante y-Position
                .attr('width', d => xScale(d) || 0)
                .attr('class', (d, i) => {
                    return "unitcompare ticketBar dgunit " + ["first", "second", "fourth", "third"][i]
                });
        }
    }, [containerWidth, containerHeight, tickets, maxTicket, lastUpdate]);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    const mTasks = tickets.full + tickets.part + tickets.not + tickets.assist

    const colorClassMap = ["first", "second", "third"];

    let dTask = (<></>);

    if (selectedDisplay.short == "norm") {
        dTask = (<div>{mTasks.toString()}</div>)
    }

    if (selectedDisplay.short == "full") {
        dTask = <div className='fullTextLine'>
            <div className='sum'>{mTasks.toString()}</div>
            <div className="first">
                {tickets.full}
            </div>
            <div className="second">
                {tickets.part}
            </div>
            <div className="third">
                {tickets.not}
            </div>

        </div>
    }

    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <div className="UnitSectorTitle">
                <div className="UnitSectorTitleText">Tickets</div>
                <div className="UnitSectorTitleProc">{dTask}</div>
            </div>
            <div className="UnitBarBackground" ref={d3Container}></div>
            <div className='SectorWrapper'>
                {isHovered && (
                    <div className="tooltip">
                        <div className='tootipLine sum' key="default">
                            <div className='toolTiptext'>Tickets gesamt</div>
                            <div className='toolTipvalue '>{mTasks}</div>
                        </div>
                        <div className='tootipLine first'>
                            <div className='toolTiptext'>Vollautomatisiert</div>
                            <div className='toolTipvalue '>{tickets.full}</div>
                        </div>
                        <div className='tootipLine second'>
                            <div className='toolTiptext'>Teilautomatisiert</div>
                            <div className='toolTipvalue '>{tickets.part}</div>
                        </div>
                        <div className='tootipLine fourth'>
                            <div className='toolTiptext'>Unterstützt</div>
                            <div className='toolTipvalue '>{tickets.assist}</div>
                        </div>
                        <div className='tootipLine third'>
                            <div className='toolTiptext'>Nicht Automatisiert</div>
                            <div className='toolTipvalue '>{tickets.not}</div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default TicketBar;

