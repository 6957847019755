import { useCallback } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

interface useDownloadStatisticProps {
    rawData: any[];
}
 
const useDownloadStatistic = () => {

    const getLine = (data: any) => {
        const ret = [];
        ret.push(data.tasks.sum || 0);
        ret.push(data.sessions || 0);
        ret.push(data.kis.default || 0);
        ret.push(data.tasks.assist || 0);
        ret.push(data.tasks.part || 0);
        ret.push(data.tasks.full || 0);

        const good = (data.tasks.full || 0) + (data.tasks.part || 0) + (data.tasks.assist || 0);
        const sum = good + (data.tasks.not || 0);

        if (sum === 0) {
            ret.push(0);
        } else {
            ret.push(parseFloat((good * 100 / sum).toFixed(2)));
        }

        return ret;
    };

    const generateSheet = (data: any, timearray: any[]) => {
        const ret: any = {};
        ret["titles"] = ["Sichtbare Tickets", "Teaching Sessions", "Deployte KIs", "Unterstützt", "Teilautomatisiert", "Vollautomatisiert", "Automatisierungsgrad"];

        timearray.forEach((dStr: any) => {
            const tData = {
                kis: data.data["kis"]?.[dStr] ?? {},
                sessions: data.data["sessions"]?.[dStr] ?? 0,
                tasks: data.data["tasks"]?.[dStr] ?? {}
            };
            ret[dStr] = getLine(tData);
        });

        return ret;
    };

    const getFormattedTimestamp = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
    };


    const downloadExcel = useCallback(async ({ rawData }: useDownloadStatisticProps) => {
        try {
            const allDates = rawData.map((ele) => {
                return Object.keys(ele.data.kis);
            }).flat().sort();

            const data = rawData.map((ele) => {
                return {
                    sheetName: ele.name,
                    dataBlock: generateSheet(ele, allDates)
                };
            });

            const workbook = new ExcelJS.Workbook();
            
            data.forEach(sheetData => {
                // Check if a sheet with the same name already exists
                let sheetName = sheetData.sheetName;
                while (workbook.getWorksheet(sheetName)) {
                    sheetName += '-dev';
                }

                const sheet = workbook.addWorksheet(sheetName);
                const { titles, ...data } = sheetData.dataBlock;

                // Set the tab title in the first row and merge cells
                const tabTitleRow = sheet.getRow(1);
                tabTitleRow.getCell(1).value = sheetName; // Use the unique sheet name
                tabTitleRow.getCell(1).font = { size: 20, bold: true }; // Set font size and bold
                tabTitleRow.getCell(1).alignment = { horizontal: 'center' }; // Center align the text
                sheet.mergeCells(1, 1, 1, titles.length + 1); // Merge cells across the entire width

                // Set the header row
                const headerRow = sheet.getRow(2);
                const headerRowValues = ['', ...titles];
                headerRow.values = headerRowValues;

                let previousValues: number[] = new Array(titles.length).fill(0); // Initialize previous values array with zeros
                let maxValues: number[] = new Array(titles.length).fill(-Infinity); // Initialize max values array
                let maxCells: ExcelJS.Cell[] = new Array(titles.length); // Initialize max cells array

                let rowCount = 3; // Start from the third row

                // Add the data rows
                Object.entries(data).forEach(([date, values]) => {
                    const row = sheet.getRow(rowCount); // Get the current row
                    const currentValues = values as number[];
                    row.values = [date, ...currentValues];

                    // Apply color formatting based on value comparison with previous row
                    currentValues.forEach((value, colIndex) => {
                        const cell = row.getCell(colIndex + 2); // +2 to account for date column
                        if (value > previousValues[colIndex]) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFCCFFCC' } // Light green
                            };
                        } else if (value < previousValues[colIndex]) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFCCCC' } // Light red
                            };
                        }

                        // Check for max value in the column
                        if (value > maxValues[colIndex]) {
                            maxValues[colIndex] = value;
                            maxCells[colIndex] = cell;
                        }
                    });

                    previousValues = currentValues; // Update previous values
                    rowCount++;
                });

                // Add a blank row
                rowCount++;

                // Add the summary row
                const summaryRow = sheet.getRow(rowCount);
                summaryRow.getCell(1).value = 'Summe';
                for (let i = 0; i < titles.length; i++) {
                    const colLetter = String.fromCharCode(66 + i); // 'B' is the second column
                    if (i < titles.length - 1) {
                        summaryRow.getCell(i + 2).value = { formula: `SUM(${colLetter}3:${colLetter}${rowCount - 1})` };
                    } 
                }

                // Apply border to the max value cells
                maxCells.forEach(cell => {
                    if (cell) {
                        cell.border = {
                            top: { style: 'thick', color: { argb: 'FF00FF00' } }, // Green border
                            left: { style: 'thick', color: { argb: 'FF00FF00' } },
                            bottom: { style: 'thick', color: { argb: 'FF00FF00' } },
                            right: { style: 'thick', color: { argb: 'FF00FF00' } }
                        };
                    }
                });

                // Auto fit columns
                (sheet.columns as ExcelJS.Column[]).forEach(column => {
                    let maxLength = 0;
                    column.eachCell({ includeEmpty: true }, cell => {
                        const columnLength = cell.value ? cell.value.toString().length : 10;
                        if (columnLength > maxLength) {
                            maxLength = columnLength;
                        }
                    });
                    column.width = maxLength < 15 ? 15 : maxLength;
                });
            });

            

            const buffer = await workbook.xlsx.writeBuffer();
            const timestamp = getFormattedTimestamp();
            const filename = `Statistik_${timestamp}.xlsx`;
            saveAs(new Blob([buffer]), filename);
        } catch (error) {
            console.error("Fehler beim Erstellen der Excel-Datei:", error);
        }
    }, []);

    return { downloadExcel };
};

export default useDownloadStatistic;
