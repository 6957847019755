import { Button } from "@hiro-ui/bootstrap";
import cx from 'classnames';
import { useContext } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";

const DownloadStatistic: React.FC = () => {

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { sendMessage } = newLocal;
   
    return (
        <div className="downloadContainer">
            <div>
                <Button
                    className={cx("downloadButton")}
                    fluid={true}
                    onClick={() => { sendMessage("downloadTrigger","")}}
                >
                    Download.xlsx
                </Button>
            </div>
        </div>
    );
}

export default DownloadStatistic;
