import { UnitButton } from "../../naviContainer/UnitSelector";
import { useContext, useEffect, useState } from "react";
import { GlobalContextProps, GlobalContext } from "../../../globalProvider";

import AutomatisierungsBarDGAll from "./AutomatisierungsBarDGAll";
import TicketBarDGAll from "./TicketBarDGAll";
import KIBarDGAll from "./KIBarDGAll";
import WiederverwendungsBarDGAll from "./WiederverwendungsBarDGAll";
import { DGunit } from "../../../dataStruct/DGUnit";

const DGAllUnitBox: React.FC = ({ }) => {

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { storage, selectedType, lastUpdate } = newLocal;

    const [dgUnit, setDgUnit] = useState<DGunit>();
    const [maxKis, setMaxKis] = useState(0);
    const [maxTasks, setMaxTasks] = useState(0);
    const [automationRates, setAutomationrates] = useState<number[]>([]);
    const [autoAutomationRate, setautoAutomationrate] = useState<number>(0);
    const [reuse, setReuse] = useState<number>(0);

    useEffect(() => {
        if (!storage.has("unitList")) {
            return;
        }

        const allUnits = storage.get("unitList") as UnitButton[];
        const myUnit = JSON.parse(JSON.stringify(allUnits[0])) as UnitButton; // Clone the Helper Element  
        let newMaxKi = 0;
        let newMaxTask = 0;
        let myReuse: number = 0;
        const myAutomationRates: number[] = [];
        const allSelected = allUnits[0].isSelected;

        allUnits.forEach((unit, key) => {
            if (key == 0) {
                return;
            }

            if (selectedType.short != "all" && unit.DGunit.type != selectedType.short) {
                return;
            }

            if (!allSelected && !unit.isSelected) {
                return;
            }

            const stat = unit.DGunit.statistic;
            myUnit.DGunit.statistic.kis.deployed += stat.kis.deployed;
            myUnit.DGunit.statistic.kis.undeployed += stat.kis.undeployed;
            myUnit.DGunit.statistic.kis.validating += stat.kis.validating;

            myUnit.DGunit.statistic.taskInfo.full += stat.taskInfo.full;
            myUnit.DGunit.statistic.taskInfo.part += stat.taskInfo.part;
            myUnit.DGunit.statistic.taskInfo.assist += stat.taskInfo.assist;
            myUnit.DGunit.statistic.taskInfo.not += stat.taskInfo.not;

            myAutomationRates.push((Math.floor(stat.rate * 10000) / 100));
            myReuse += stat.reuse;
        })

        const stat = myUnit.DGunit.statistic;
        const mKi = stat.kis.deployed + stat.kis.undeployed + stat.kis.validating;
        const mTa = stat.taskInfo.full + stat.taskInfo.part + stat.taskInfo.not + stat.taskInfo.assist
        const mTf = stat.taskInfo.full + stat.taskInfo.part + stat.taskInfo.assist
        const autoRate = Math.round(mTf / mTa * 10000) / 100;

        newMaxKi = mKi //Math.max(mKi, maxKis);
        newMaxTask = mTa //Math.max(mTa, maxTasks);
        myReuse = myReuse / allUnits.length;

        setDgUnit(myUnit.DGunit);
        setMaxKis(newMaxKi);
        setMaxTasks(newMaxTask);
        setAutomationrates(myAutomationRates);
        setautoAutomationrate(autoRate);
        setReuse(myReuse);

    }, [storage, selectedType, lastUpdate])

    const unit = dgUnit;

    if (!unit) {
        return <></>;
    }

    const conf = storage.get("config");

    return (
        <div className="DGAllBox">
            <div className="DGAllBoxTitle">{conf.HIRO_COMPANY_FULL_NAME}</div>
            <div className="DGAllBoxContent">
                <div className="DGAllSector">
                    <TicketBarDGAll
                        tickets={unit.statistic.taskInfo}
                        maxTicket={maxTasks}
                    />
                </div>
                <div className="DGAllSector">
                    <KIBarDGAll
                        kis={unit.statistic.kis}
                        maxKi={maxKis}
                    />
                </div>
                <div className="DGAllSector">
                    <AutomatisierungsBarDGAll
                        automationRates={automationRates}
                        autoAutomationRate={autoAutomationRate}
                    />
                </div>

                <div className="DGAllSector">
                    <WiederverwendungsBarDGAll
                        rate={reuse} 
                    />
                </div>
            </div>

        </div>
    )
}

export default DGAllUnitBox;