import * as d3 from 'd3';
import { useContext, useRef, useState, useEffect } from "react";
import { GlobalContextProps, GlobalContext } from "../../../globalProvider";

export type AutomationCircleProps = {
    title: string;
    id: string;
    value: number;
}

const AutomationCircle: React.FC<AutomationCircleProps> = ({ id, title, value }) => {

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { selectedDisplay } = newLocal;

    const d3Container = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const cArc = 360 * value / 100;

    useEffect(() => {
        let isMounted = true;
        if (d3Container.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    window.requestAnimationFrame(() => {
                        if (isMounted) {
                            setContainerWidth(entry.contentRect.width);
                            setContainerHeight(entry.contentRect.height);
                        }
                    });

                }
            });

            resizeObserver.observe(d3Container.current);
            return () => {
                isMounted = false;
                resizeObserver.disconnect();
            };
        }
    }, [d3Container]);

    useEffect(() => {
        if (containerWidth > 0 && containerHeight > 0) {

            d3.select(d3Container.current).selectAll("*").remove();
            const svg = d3.select(d3Container.current).append('svg')
                .attr('width', containerWidth)
                .attr('height', containerHeight);

            const defs = svg.append("defs")
            const filter = defs.append("filter")
                .attr("id", "shadowBack")
                .attr("filterUnits", "userSpaceOnUse")

            filter.append("feOffset")
                .attr("dy", "3")
                .attr("input", "SourceAlpha")

            filter.append("feGaussianBlur")
                .attr("stdDeviation", "3")
                .attr("result", "blur")

            filter.append("feFlood")
                .attr("flood-color", "#aaa")
                .attr("result", "color")

            filter.append("feComposite")
                .attr("operator", "out")
                .attr("in", "SourceGraphic")
                .attr("in2", "blur")

            filter.append("feComposite")
                .attr("operator", "in")
                .attr("in", "color")

            filter.append("feComposite")
                .attr("operator", "in")
                .attr("in2", "SourceGraphic")

            const filter2 = defs.append("filter")
                .attr("id", "shadowArcBack")
                .attr("filterUnits", "userSpaceOnUse")

            filter2.append("feOffset")
                .attr("dy", "7")
                .attr("input", "SourceAlpha")

            filter2.append("feGaussianBlur")
                .attr("stdDeviation", "3")
                .attr("result", "blur2")

            filter2.append("feFlood")
                .attr("flood-opacity", "0.161")
                .attr("result", "color2")

            filter2.append("feComposite")
                .attr("operator", "out")
                .attr("in", "SourceGraphic")
                .attr("in2", "blur2")

            filter2.append("feComposite")
                .attr("operator", "in")
                .attr("in", "color2")

            filter2.append("feComposite")
                .attr("operator", "in")
                .attr("in2", "SourceGraphic")

            const g = svg.append("g");

            g.append("path")
                .attr("d", "M60,0A60,60,0,1,1,0,60,60,60,0,0,1,60,0Z")
                .attr("class","automat circleBackground")

            g.append("path")
                .attr("d", "M60,0A60,60,0,1,1,0,60,60,60,0,0,1,60,0Z")
                .attr("filter", "url(#shadowBack)")
                .attr("class","automat circleShadow")

            const arcGenerator = d3.arc()
                .innerRadius(0)
                .outerRadius(60)
                .startAngle(0)

            g.append('path')
                .datum({ endAngle: 0 })
                .attr('d', arcGenerator({ endAngle: 1 } as any))
                .attr('class', 'rateCircle ' + id)
                .attr('transform', 'translate(' + containerWidth / 2 + ', ' + containerHeight / 2 + ')')
                
                .transition()
                .duration(1000)
                .attrTween('d', function (d) {
                    const interpolate = d3.interpolate(0, 2 * Math.PI * (cArc / 360));
                    return function (t) {
                        return arcGenerator({ endAngle: interpolate(t) } as any) ?? "";
                    };
                });
                

        }
    }, [containerWidth, containerHeight, cArc]);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    
    const vDisp = Math.round(value * 100) / 100;

    
    return (
        <div className="AutomationCircleRateBox">
            <div className="UnitSectorTitle ">
                <div className="UnitSectorTitleText automationCircleTitleText">{title} - </div>
                <div className="UnitSectorTitleProc"> {vDisp}%</div>
            </div>
            <div className="AutomationCircleBackground" ref={d3Container}>
            </div>
        </div>
    )
}

export default AutomationCircle