import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";
import { UnitButton } from "../naviContainer/UnitSelector";
import DGAllKnowledgeBox from './Wissenstransfer/DGAllKnowledgeBox';
import cx from 'classnames';
import UnitKnowledgeBox from "./Wissenstransfer/UnitKnowledgeBox";
import { DGunit } from "../../dataStruct/DGUnit";

type waterfallStepConnector = {
    toId: string;
    markSufficientBorder?: (pre: waterfallBar, post: waterfallBar) => boolean;
    dataCreator: (pre: waterfallBar, post: waterfallBar) => string;
}

type waterfallStep = {
    id: string;
    label: string;
    connect: waterfallStepConnector[];
    dataCreator: (unit: DGunit) => number;
}

export type maxList = {
    [key: string]: number;
}

export const waterfallStruct: waterfallStep[] = [
    {
        label: 'Ticketanzahl',
        id: 'fullTicket',
        dataCreator: (unit: DGunit) => {
            return unit.statistic.taskInfo.full + unit.statistic.taskInfo.part + unit.statistic.taskInfo.not + unit.statistic.taskInfo.assist;
        },
        connect: [{
            toId: 'nonautoTicket',
            dataCreator: (pre: waterfallBar, post: waterfallBar) => {
                const max = pre.value;
                const val = post.value;
                const perc = Math.round(10000 * val / max) / 100
                return (`${perc} % genutzt`);
            }
        }]
    },
    {
        label: 'Teil-/Nicht',
        id: 'nonautoTicket',
        dataCreator: (unit: DGunit) => {
            return unit.statistic.taskInfo.part + unit.statistic.taskInfo.not + unit.statistic.taskInfo.assist;
        },
        connect: [{
            toId: 'smeSession',
            markSufficientBorder: (pre: waterfallBar, post: waterfallBar) => {
                const max = Math.max(pre.value, 1);
                const val = post.value;
                const perc = Math.round(10000 * val / max) / 100;
                if (perc == 0) {
                    return true;
                }
                return perc < 20;
            },
            dataCreator: (pre: waterfallBar, post: waterfallBar) => {
                const max = Math.max(pre.maxVal, 1);
                const val = post.value;
                const perc = Math.round(10000 * val / max) / 100
                return (`${perc} % genutzt`);
            }
        }]
    }, {
        label: 'SME Sessions',
        id: 'smeSession',
        dataCreator: (unit: DGunit) => {
            return unit.statistic.allSME;
        },
        connect: [{
            toId: 'smeSteps',
            dataCreator: (pre: waterfallBar, post: waterfallBar) => {
                const max = Math.max(pre.value, 1);
                const val = post.value;
                const avg = Math.round(100 * val / max) / 100
                return (`${avg} Schritte durchschnittlich`);
            }
        }]
    }, {
        label: 'SME Steps',
        id: 'smeSteps',
        dataCreator: (unit: DGunit) => {
            return unit.statistic.smeSteps;
        },
        connect: [{
            toId: 'convertedSteps',
            dataCreator: (pre: waterfallBar, post: waterfallBar) => {
                const max = Math.max(pre.maxVal, 1);
                const val = post.value;
                const avg = Math.round(10000 * val / max) / 100
                return (`${avg} % konvertiert`);
            }
        }]
    }, {
        label: 'Converted Steps',
        id: 'convertedSteps',
        dataCreator: (unit: DGunit) => {
            return unit.statistic.convertedSteps;
        },
        connect: [{
            toId: 'kislive',
            dataCreator: (pre: waterfallBar, post: waterfallBar) => {
                const max = Math.max(pre.value, 1);
                const val = post.value;
                const avg = Math.round(10000 * val / max) / 100
                return (`${avg} % live`);
            }
        }]
    }, {
        label: 'KIs live',
        id: 'kislive',
        dataCreator: (unit: DGunit) => {
            return unit.statistic.kis.deployed;
        },
        connect: []
    }
]

type flowConnector = {
    start: number;
    end: number;
    markSufficientBorder: boolean;
    poststart: number; // Startpunkt des Nachfolgers
    postend: number; // Endpunkt des Nachfolgers
    postmax: number; // Skala des Nachfolgers
    label: string;
}

export type waterfallBar = {
    id: string;
    label: string; // der Label für den Balken
    value: number; // der Wert des Balken
    maxVal: number; // maximaler Wert dieses Balkens in allen Einheiten
    flowConnectors: flowConnector[]
}

const KnowledgeTransfer: React.FC = () => {
    const parentRef = useRef<HTMLDivElement>(null);

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { storage, registerEventListener, unregisterEventListener, selectedModule, selectedType } = newLocal;
    const [isOpen, setIsOpen] = useState(true);
    const [dgUnit, setDgUnit] = useState<DGunit>();
    const [maxVals, setMaxVals] = useState<maxList>({});
    const [maxUnitVals, setMaxUnitVals] = useState<maxList>({});
    const [sort, setSort] = useState<((a: UnitButton, b: UnitButton) => number) | null>(() => selectedModule.currentSort?.sortFunc || null);




    const forceResize = () => {
        window.setTimeout(() => {
            //Synthetic Event to trigger resize in case the FilterBox is resized
            const event = new Event('resize');
            window.dispatchEvent(event);
        }, 20)
    }


    useEffect(() => {
        if (!storage.has("unitList")) {
            return;
        }

        const allUnits = storage.get("unitList") as UnitButton[];
        const myUnit = JSON.parse(JSON.stringify(allUnits[0])) as UnitButton; // Clone the Helper Element

        //Prepare Data


        let newUnitMaxVals: maxList = {
            'fullTicket': 0,
            'nonautoTicket': 0,
            'smeSession': 0,
            'smeSteps': 0,
            'convertedSteps': 0,
            'kislive': 0
        }

        const allSelected = allUnits[0].isSelected;

        allUnits.forEach((unit, key) => {
            if (key == 0) {
                return;
            }

            if (selectedType.short != "all" && unit.DGunit.type != selectedType.short) {
                return;
            }
            if (!allSelected && !unit.isSelected) {
                return;
            }

            const stat = unit.DGunit.statistic;
            myUnit.DGunit.statistic.allSME += stat.allSME;
            myUnit.DGunit.statistic.taskInfo.full += stat.taskInfo.full;
            myUnit.DGunit.statistic.taskInfo.assist += stat.taskInfo.assist;
            myUnit.DGunit.statistic.taskInfo.part += stat.taskInfo.part;
            myUnit.DGunit.statistic.taskInfo.not += stat.taskInfo.not;
            myUnit.DGunit.statistic.smeSteps += stat.smeSteps;
            myUnit.DGunit.statistic.convertedSteps += stat.convertedSteps;
            myUnit.DGunit.statistic.kis.deployed += stat.kis.deployed;


            newUnitMaxVals = {
                'fullTicket': Math.max(newUnitMaxVals.fullTicket, stat.taskInfo.full + stat.taskInfo.part + stat.taskInfo.not + stat.taskInfo.assist),
                'nonautoTicket': Math.max(newUnitMaxVals.nonautoTicket, stat.taskInfo.part + stat.taskInfo.not + stat.taskInfo.assist),
                'smeSession': Math.max(newUnitMaxVals.smeSession, stat.allSME),
                'smeSteps': Math.max(newUnitMaxVals.smeSteps, stat.smeSteps),
                'convertedSteps': Math.max(newUnitMaxVals.convertedSteps, stat.convertedSteps),
                'kislive': Math.max(newUnitMaxVals.kislive, stat.kis.deployed)
            }

        })

        const stat = myUnit.DGunit.statistic;

        // calculate  bars

        const maxSME = Math.max((stat.taskInfo.full + stat.taskInfo.part + stat.taskInfo.not + stat.taskInfo.assist) * 0.3, myUnit.DGunit.statistic.allSME);
        const maxSteps = Math.max(myUnit.DGunit.statistic.allSME * 5, myUnit.DGunit.statistic.smeSteps);
        const maxConvert = maxSteps * 1.2

        const newMaxVals: maxList = {
            'fullTicket': stat.taskInfo.full + stat.taskInfo.part + stat.taskInfo.not + stat.taskInfo.assist,
            'nonautoTicket': stat.taskInfo.full + stat.taskInfo.part  + stat.taskInfo.assist,
            'smeSession': maxSME,
            'smeSteps': maxSteps,
            'convertedSteps': maxConvert,
            'kislive': maxConvert
        }



        setDgUnit(myUnit.DGunit);
        setMaxVals(newMaxVals);
        setMaxUnitVals(newUnitMaxVals);

    }, [storage, sort, selectedType])

    useEffect(() => {
        const list = registerEventListener("naviResize", forceResize)
        const sortListener = registerEventListener("changeSort", (ele) => {
            setSort(() => ele.sortFunc)
        })

        return (() => {
            unregisterEventListener("naviResize", list)
            unregisterEventListener("changeSort", sortListener)
        })

    }, [])

    const handleClick = () => {

        setIsOpen(!isOpen)
    }

    if (!storage.has("unitList")) {
        return (<div>No Units</div>)
    }

    const myUnits = storage.get("unitList") as UnitButton[];
    if (myUnits.length == 0) return (<div>No Units</div>)
    if (!dgUnit) return (<div>No Units</div>)

    const showAll = myUnits[0].isSelected;
    const conf = storage.get("config");

    let sFunc = (a: UnitButton, b: UnitButton) => { return 0 }
    if (sort) sFunc = sort


    return (
        <div className="allUnitContainer" ref={parentRef}>
            <div className={cx("DGAllWrapperBox DGAllWrapperBoxFix", [{ "minimized": !isOpen }])}>
                <div className={cx("DGAllWrapperFixed verticalFixWidth", [{ "minimized": !isOpen }])}>
                    <div className="DGAllSeperator " onClick={handleClick}>
                        {
                            isOpen && (<div className="minimizeHeaderFlap"><FontAwesomeIcon icon={faAngleUp} /></div>)
                        }
                        {
                            !isOpen && (<div className="minimizeHeaderFlap"><FontAwesomeIcon icon={faAngleDown} /></div>)
                        }

                    </div>
                    {isOpen && (<>
                        <div className="DGMainFixedcontainer verticalFixContainer">
                            <DGAllKnowledgeBox
                                data={dgUnit}
                                maxList={maxVals}
                            />
                        </div>
                        <div className="DGLegendFixContainer verticalFixContainer">

                        </div>
                    </>)}
                    {!isOpen && (
                        <div className="DGMinimizedFixed"> {conf.HIRO_COMPANY_FULL_NAME} | alle Daten</div>
                    )}
                    <div className="DGAllSeperator"></div>
                </div>
            </div>
            <div className="unitWrapper">
                {
                    [...myUnits]
                        .sort(sFunc)
                        .map((ele: UnitButton, position: number) => {
                            if (ele.id == 0) {
                                return;
                            }
                            if (selectedType.short != "all" && ele.DGunit.type != selectedType.short) {
                                return;
                            }

                            // show only selected or all
                            if (showAll || ele.isSelected) {
                                return (
                                    <UnitKnowledgeBox
                                        data={ele.DGunit}
                                        maxList={maxUnitVals}
                                        key={position}
                                    />
                                )
                            }

                            return;

                        })}
            </div>
        </div>
    )
}

export default KnowledgeTransfer;