import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";
import fixedPositionWidth from "../helper/fixedPositionWidth";
import { UnitButton } from "../naviContainer/UnitSelector";
import cx from 'classnames';
import { HistSequenceBlock } from "../../dataStruct/HistData";
import HistorienLegende from "./Historie/HistorienLegende";
import DGAllHistoryBox from "./Historie/DGAllHistoryBox";
import './Historie/Historie.scss';
import HistoryUnitBox, { HistoryUnitBoxHandle } from "./Historie/HistoryUnitBox";
import useDownloadStatistic from "./Historie/useDownloadStatistic";

type AccumulatorType = {
    [key: string]: number;
};

export type allUnitType = { [key: string]: HistSequenceBlock };

const Historie: React.FC = () => {
    const unitRefs = useRef<HistoryUnitBoxHandle[]>([]);
    const parentRef = useRef<HTMLDivElement>(null);
    const width = fixedPositionWidth(parentRef);
    const { downloadExcel } = useDownloadStatistic();

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { storage, registerEventListener, unregisterEventListener, selectedModule, selectedType, histData, selectedSequence, selectedHistory } = newLocal;

    const [isOpen, setIsOpen] = useState(true);
    const [allUnit, setAllUnit] = useState<allUnitType>();
    const [startTick, setStartTick] = useState<string>("");

    const [sort, setSort] = useState<((a: UnitButton, b: UnitButton) => number) | null>(() => selectedModule.currentSort?.sortFunc || null);

    const forceResize = () => {
        window.setTimeout(() => {
            //Synthetic Event to trigger resize in case the FilterBox is resized
            const event = new Event('resize');
            window.dispatchEvent(event);
        }, 20)
    }

    const handleEvent = useCallback(() => {
        // Werte von allen Boxen lesen
        const values = unitRefs.current.map(ref => ref?.getInnerData());
        downloadExcel({ rawData: values });
    }, []);

    const startDownload = () => {
        handleEvent();
    }

    useEffect(() => {
        if (!histData) {
            return;
        }

        if (!storage.has("unitList")) {
            return;
        }

        const mergeAndSum = (obj1: any, obj2: any) => {
            const keys = Array.from(new Set([...Object.keys(obj1), ...Object.keys(obj2)]));
            const mergedObject = keys.reduce<AccumulatorType>((acc, key) => {
                acc[key] = (obj1[key] || 0) + (obj2[key] || 0);
                return acc;
            }, {});

            return mergedObject;
        }

        const convertDateToISOWeek = (dateString: string) => {
            const date = new Date(dateString);
            date.setHours(12, 0, 0, 0);
            const dayOfWeek = (date.getDay() + 6) % 7 + 1;
            date.setDate(date.getDate() + (dayOfWeek !== 1 ? 8 - dayOfWeek : 1));
            const fourthOfJanuary = new Date(date.getFullYear(), 0, 4);
            const dayDifference = (date.getTime() - fourthOfJanuary.getTime()) / (86400000);
            const weekNumber = Math.ceil((fourthOfJanuary.getDay() + 1 + dayDifference) / 7);
            return `${date.getFullYear()}-W${String(weekNumber).padStart(2, '0')}`;
        }

        let newMaxKi = 0;
        let newMaxTask = 0;

        const allUnits = storage.get("unitList") as UnitButton[];
        const allSelected = allUnits[0].isSelected;
        const nUnit: allUnitType = {};

        const tickList: string[] = [];

        allUnits.forEach((unit, key) => {

            if (key == 0) {
                return;
            }

            if (selectedType.short != "all" && unit.DGunit.type != selectedType.short) {
                return;
            }

            if (!allSelected && !unit.isSelected) {
                return;
            }

            const mKey: string = unit.DGunit.hiroId;
            nUnit[mKey] = histData[mKey][selectedSequence.sequence];

            const convertBase = histData[mKey]["days"]

            //handle tasks
            const cTask = convertBase["tasks"];
            const nTask: any = {}

            Object.keys(cTask).forEach((dstr) => {
                let ndstr = dstr;
                switch (selectedSequence.sequence) {
                    case "days": {
                        //do nothing
                        break;
                    }
                    case "weeks": {
                        ndstr = convertDateToISOWeek(dstr);
                        break;
                    }
                    case "month": {
                        ndstr = dstr.substring(0, 7);
                        break;
                    }
                    case "year": {
                        ndstr = dstr.substring(0, 4);
                        break;
                    }
                }

                if (!Object.keys(nTask).includes(ndstr)) {
                    nTask[ndstr] = cTask[dstr];
                } else {
                    nTask[ndstr] = mergeAndSum(nTask[ndstr], cTask[dstr]);
                }
            })

            nUnit[mKey]["tasks"] = nTask;

            //handle tasks
            const cKi = convertBase["kis"];
            const nki: any = {}

            Object.keys(cKi).forEach((dstr) => {

                let ndstr = dstr;
                switch (selectedSequence.sequence) {
                    case "days": {
                        //do nothing
                        break;
                    }
                    case "weeks": {
                        ndstr = convertDateToISOWeek(dstr);
                        break;
                    }
                    case "month": {
                        ndstr = dstr.substring(0, 7);
                        break;
                    }
                    case "year": {
                        ndstr = dstr.substring(0, 4);
                        break;
                    }
                }

                if (!Object.keys(nki).includes(ndstr)) {
                    nki[ndstr] = cKi[dstr];
                } else {
                    nki[ndstr] = mergeAndSum(nki[ndstr], cKi[dstr]);
                }
                tickList.push(ndstr);
            })

            nUnit[mKey]["kis"] = nki;

            for (const entry of Object.values(nUnit[mKey])) {
                let sumup: number = 0;
                for (const val of Object.values(entry)) {
                    sumup = Object.values(val).reduce((acc: any, curr: any) => acc + curr, 0) as number || 0
                }

                newMaxKi = Math.max(newMaxKi, sumup);
            }

            for (const entry of Object.values(nUnit[mKey])) {
                let sumup: number = 0;
                for (const val of Object.values(entry)) {
                    sumup = Object.values(val).reduce((acc: any, curr: any) => acc + curr, 0) as number || 0
                }

                newMaxTask = Math.max(newMaxTask, sumup);
            }
        })
        setStartTick(tickList.sort()[0])
        setAllUnit(nUnit);
        forceResize();
    }, [storage, sort, selectedType, histData, selectedSequence, selectedHistory])

    useEffect(() => {
        const list = registerEventListener("naviResize", forceResize)
        const sortListener = registerEventListener("changeSort", (ele) => {
            setSort(() => ele.sortFunc)
        });

        const downloadTrigger = registerEventListener("downloadTrigger", startDownload);

        return (() => {
            unregisterEventListener("naviResize", list)
            unregisterEventListener("changeSort", sortListener)
            unregisterEventListener("downloadTrigger", downloadTrigger)
        })

    }, [])

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    if (!storage.has("unitList") || !allUnit) {
        return (<div>No Units</div>)
    }

    const myUnits = storage.get("unitList") as UnitButton[];
    const conf = storage.get("config");
    const showAll = myUnits[0].isSelected;

    let sFunc = (a: UnitButton, b: UnitButton) => { return 0 }
    if (sort) sFunc = sort;

    unitRefs.current = [];


    const addToRefs = (el: HistoryUnitBoxHandle | null) => {
        if (isOpen && el) {
            let found = false;
            unitRefs.current.forEach((el) => {
                found = found || el.getInnerData().isAll

            })
            if (!found) {
                unitRefs.current.push(el);
            }
        }
    };

    return (
        <div className="allUnitContainer" ref={parentRef}>
            <div className={cx("DGAllWrapperBox autoWrapper", [{ "minimized": !isOpen }])}>
                <div className={cx("DGAllWrapperFixed", [{ "minimized": !isOpen }])} style={{ width: width }}>
                    <div className="DGAllSeperator " onClick={handleClick}>
                        {
                            isOpen && (<div className="minimizeHeaderFlap"><FontAwesomeIcon icon={faAngleUp} /></div>)
                        }
                        {
                            !isOpen && (<div className="minimizeHeaderFlap"><FontAwesomeIcon icon={faAngleDown} /></div>)
                        }

                    </div>
                    {isOpen && (<>
                        <div className="DGMainFixedcontainer DGMainFixedcontainerHistoryFix">
                            <DGAllHistoryBox
                                data={allUnit}
                                ref={addToRefs}
                            />
                        </div>
                        <div className="DGLegendFixContainer">
                            <HistorienLegende />
                        </div>
                    </>)}
                    {!isOpen && (
                        <div className="DGMinimizedFixed"> {conf.HIRO_COMPANY_FULL_NAME} | alle Daten</div>
                    )}
                    <div className="DGAllSeperator"></div>
                </div>
            </div>
            <div className="unitWrapper">
                {
                    [...myUnits]
                        .sort(sFunc)
                        .map((ele: UnitButton, position: number) => {

                            if (ele.id == 0) {
                                return;
                            }

                            if (selectedType.short != "all" && ele.DGunit.type != selectedType.short) {
                                return;
                            }

                            // show only selected or all
                            if (showAll || ele.isSelected) {
                                return (
                                    <HistoryUnitBox
                                        key={ele.id}
                                        unit={ele}
                                        histData={allUnit[ele.DGunit.hiroId]}
                                        startTick={startTick}
                                        ref={el => {
                                            if (el) {
                                                unitRefs.current.push(el);
                                            }
                                        }}
                                    />
                                )
                            }

                            return;

                        })}
            </div>
        </div>
    )
}

export default Historie;
