
import { Container, GlobalStyle } from '@hiro-ui/bootstrap';
import React from "react";
import NaviContainer from './naviContainer/FilterContainer';
import GraphContainer from './modules/GraphContainer';
import HeaderContainer from './header/HeaderContainer';
import ModuleSelector from './naviContainer/ModuleSelector';

import './appContainer.scss';
import './svg.scss';
import './media.scss';
import InfoWindow from './infoWindow/InfoWindow';


const AppMainContainer: React.FC = () => {
    return (
        <Container
            fluid={true}
            className="masterContainer"
        >
            <InfoWindow />
            <GlobalStyle />
            <HeaderContainer />
            <div className="mainContainer">
                <ModuleSelector />
                <NaviContainer />
                <GraphContainer />
            </div>
        </Container>
    )
}
export default AppMainContainer;

