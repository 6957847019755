
const HumanLegende: React.FC = ({ }) => {

    return (
        <>
            <div className="legendBox">
                <div className="legendTitle">Nutzer:</div>
                <div className="legendLines">
                    <div className="humanLegendColorBox first"></div>
                    <div className="legendText">SME + KE</div>
                </div>
                <div className="legendLines">
                    <div className="humanLegendColorBox second"></div>
                    <div className="legendText">SME</div>
                </div>
                <div className="legendLines">
                    <div className="humanLegendColorBox third"></div>
                    <div className="legendText">KE</div>
                </div>
                <div className="legendLines">
                    <div className="humanLegendColorBox fourth"></div>                
                    <div className="legendText">Aktiv</div>
                </div>
            </div>         
        </>
    )
}

export default HumanLegende;
