
import DGAllTickerMasterBox from "./DGAllTickerMasterBox";
import './Automation.scss';
import DGAllBarMasterBox from "./DGAllBarMasterBox";
import DGAllGaugeMasterBox from "./DGAllGaugeMasterBox";
import { UnitButton } from "../../naviContainer/UnitSelector";
import { useContext } from "react";
import { GlobalContextProps, GlobalContext } from "../../../globalProvider";



type DGAllAutomationBoxProps = {
    unit: UnitButton;
    maxTask: number;
    maxAutoTickets: number;
    maxUser: number;
}

const DGAllAutomationBox: React.FC<DGAllAutomationBoxProps> = ({ unit,maxTask,maxUser,maxAutoTickets }) => {

    if (!unit) {
        return <></>;
    }

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { graph, storage } = newLocal;
    const conf = storage.get("config");

    return (<div className="DGAllBox" >
        <div className="DGAllBoxTitle">{conf.HIRO_COMPANY_FULL_NAME}</div>
        <div className="DGAllBoxContent">
            <div className="DGAllBigSector">
                <DGAllTickerMasterBox
                    dgUnit={unit.DGunit}
                    maxTickets={maxTask}
                />
            </div>
            <div className="DGAllBigSector">
                <DGAllBarMasterBox
                    dgUnit={unit.DGunit}
                    maxAutoTickets={maxAutoTickets}
                    maxUser={maxUser}
                />
            </div>
            <div className="DGAllBigSector">
                <DGAllGaugeMasterBox
                    unit={unit}
                />
            </div>
            
        </div>
    </div>)
}

export default DGAllAutomationBox;