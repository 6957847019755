import { ReactElement } from "react"

type infoText = {
    [key: string]: ReactElement
}
const infoTexts: infoText = {}


/*UNIT Page*/
infoTexts["unitcompareTickets"] = (<div>
    <h3>Tickets</h3>
    <p>Zeigt wie viele Tickets sind insgesamt im System eingegangen und wie viele davon Voll-, Teil- oder Nichtautomatisiert wurden.</p>
</div>)
infoTexts["unitcompareKnowledgeItems"] = (<div>
    <h3>Wissen</h3>
    <p>Zeigt wie viel Wissen (Wissensbausteine, englisch = Knowledge Items = KI) sich im System befinden. Drei Kategorien werden dargestellt:
        <ul>
            <li>Produktion – im System der Produktion.</li>
            <li>Validierung – im Peer Review Prozess.</li>
            <li>Entwicklung – in der Entwicklungsphase.</li>
        </ul>
    </p>
</div>)
infoTexts["unitcompareAutomation"] = (<div>
    <h3>Automatisierung</h3>
    <p>Wie viel der vorhandenen Tickets werden automatisch erledigt und wie groß ist die Abweichung zwischen den einzelnen Einheiten:
        <ul>
            <li>Der Automatisierungsgrad wird durch eine rote Linie gekennzeichnet. Dabei wird dieser über die gesamt verarbeiteten Tickets gerechnet, also wie viele Tickets wurden wirklich automatisiert. </li>
            <li>Der graue Bereich zeigt den Wertebereich für die Automatisierung entweder zwischen Einheiten (Summenansicht) oder der Automatisierungsgrad der Einheit. </li>
        </ul>
    </p>
</div>)
infoTexts["unitcompareReuse"] = (<div>
    <h3>Wiederverwendung</h3>
    <p>Dies ist eine Kennzahl für die Qualität des übermittelten Wissens. Der Faktor wird künstlich errechnet.</p>
    <p>
        Die Formel ist wie folgt: Durchschnitt von allen Issues die eine spezielle KI nutzen geteilt durch alle Issues. Es werden alle Tickets betrachtet, die gelöst sind und mindestens drei KIs zur Ausführung gebracht haben. Dabei wird ermittelt, ob die Wissensbausteine so an das System übergeben wurden, dass das System korrekt arbeiten kann oder ob nur „Skripte“ in das System eingegeben wurden, deren einzelne Bausteine nicht wiederverwendet werden können.
    </p>
    <p>Dabei wird ermittelt, ob die Wissensbausteine so an das System übergeben wurden, dass das System korrekt arbeiten kann oder ob nur „Skripte“ in das System eingegeben wurden, deren einzelne Bausteine nicht wiederverwendet werden können.</p>
</div>)

infoTexts["unitcompareInfoContainerScore"] = (<div>
    <h3>Score</h3>
    <p>
        Es wird die Automatisierungsrate mit der Wiederverwendungsrate und 10.000 multipliziert.
    </p>
</div>)



/*KNOWLEDGE Page*/

/*HUMAN Page*/
infoTexts["humanUser"] = (<div><h3>Nutzer</h3>
    <p>Wie viele Nutzer gibt es insgesamt und wie viele davon haben nur die Rolle SME (Fach-Experte), wie viele haben nur die Rolle des KE (Wissensformalisierer) und wie viele haben beide Rollen (SME und KE)</p>
</div>)
infoTexts["humanKnowledgeItems"] = (<div><h3>Wissen Generiert</h3>
    <p>Wie viele Wissensbausteine wurden insgesamt pro Nutzer generiert. Die Darstellung zeigt den gewichteten Durchschnitt als roten Strich, den nicht gewichteten Durchschnitt als grauen Strich und das Spektrum als grauen Hintergrund. </p>
</div>)
infoTexts["humanKnowledgeTransfer"] = (<div><h3>Wissenstransfer</h3>
    <p>Wie viel Wissen wurde prozentual an das System übertragen. Sprich für wie viele Tickets wurden tatsächlich Transfersessions angestoßen. Der Zielwert ist 20%. Die Darstellung zeigt den gewichteten Durchschnitt als roten Strich, den nicht gewichteten Durchschnitt als grauen Strich und das Spektrum als grauen Hintergrund. </p>
</div>)
infoTexts["humanMultiplicationFactor"] = (<div><h3>Multiplikationsfaktor</h3>
    <p>ist eine künstlich errechnete Kennzahl, die wie folgt bestimmt wird: Generierte SME-Sessions geteilt durch (alle nicht automatisierte Tickets geteilt durch die Anzahl der Nutzer) mal 100. Sie zeigt wie gut die von den Experten übertragenen Wissenseinheiten tatsächlich angewendet werden können. Ein hoher Wert ist hier wünschenswert. Die Darstellung zeigt den gewichteten Durchschnitt als roten Strich, den nicht gewichteten Durchschnitt als grauen Strich und das Spektrum als grauen Hintergrund. </p>
</div>)
infoTexts["humanReuseFactor"] = (<div><h3>Wiederverwendung</h3>
    <p>Beschreibt den Anteil aller KIs eines Users an allen Tickets der Unit. Er zeigt an, wie gut das von den Experten erfasste und formalisierte Wissen eingesetzt werden kann, um der Maschine zu ermöglichen eigene Abfolgen zu generieren. Je höher dieser Faktor, desto exponentieller wächst die Automation. Die Darstellung zeigt den gewichteten Durchschnitt als roten Strich, den nicht gewichteten Durchschnitt als grauen Strich und das Spektrum als grauen Hintergrund.</p>
</div>)

/*AUTIMATION Page*/
infoTexts["automationTickets"] = (<div><h3>Tickets</h3>
    <p>Zeigt die gesamte Anzahl Tickets und wie viele davon vollautomatisiert bzw. teilautomatisiert wurden. </p>
</div>)
infoTexts["automationAutomationRate"] = (<div><h3>Automatisierungsrate</h3>
    <p>Zeigt in drei Kuchendiagrammen wie sich diese Absolutwerte prozentual verteilen. Also wie viel Prozent der Tickets vollautomatisiert wurden, wie viel Prozent teilautomatisiert wurden und die Summe dieser beiden Zahlen – also wie viel Prozent insgesamt in irgend einer Weise automatisiert wurden. </p>
</div>)
infoTexts["automationHumanInvolved"] = (<div><h3>Nutzer beteiligt</h3>
    <p><strong>XXX</strong></p>
</div>)
infoTexts["automationNoneHiroInvolved"] = (<div><h3>Ohne Hiro wären es</h3>
    <p>Zeigt wie viel manueller Aufwand ohne HIRO entstanden wäre</p>
</div>)
infoTexts["automationTicketCost"] = (<div><h3>Kosten pro Ticket</h3>
    <p>Errechnet die durchschnittlichen Kosten pro Ticket in der Gesamtzahl zwischen Mensch und Maschine. </p>
</div>)
infoTexts["automationTicketCostNoneHiro"] = (<div><h3>Kosten ohne Hiro</h3>
    <p>Zeigt die Kosten pro Ticket an, wenn HIRO nicht zum Einsatz kommen würde. </p>
</div>)
infoTexts["automationReactionFactor"] = (<div><h3>Reaktionsfaktor</h3>
    <p>zeigt an, um wie viel – also um welchen Multiplikationsfaktor – der Kunde schneller bedient wurde, weil HIRO zum Einsatz kam. Dies wird nur auf die vollautomatisierten oder  teilautomatisierten Tickets angewendet.</p>
</div>)





export default infoTexts;
