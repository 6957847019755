
import { UnitButton } from "../../naviContainer/UnitSelector";
import GaugeBox from "./GaugeBox";

interface DGAllGaugeMasterBoxProps {
    unit: UnitButton
}

const DGAllGaugeMasterBox: React.FC<DGAllGaugeMasterBoxProps> = ({ unit }) => {
    
    const factors = unit.meta as number[]

    const max = factors.reduce((a, b) => Math.max(a, b), -Infinity);
    const average = Math.round(factors.reduce((sum, current) => sum + current, 0) / factors.length * 100) / 100;
    const stdDeviation = Math.round(Math.sqrt(
        factors.reduce((sum, current) => sum + Math.pow(current - average, 2), 0) / factors.length
    )* 100) / 100;



    return (
        <div className="gaugeMasterContainer">
            <GaugeBox
                maxVal={max}
                val={average}
                std={stdDeviation}
            />
        </div>
    )
}

export default DGAllGaugeMasterBox;