import React, { useContext, useState, useEffect } from "react";
import { GlobalContextProps, GlobalContext } from "./globalProvider";

type CriticalProps = {
    errorCode: number
}

const CriticalError: React.FC<CriticalProps> = ({ errorCode }) => {
    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { storage } = newLocal;
    const conf = storage.get("config");

    const [seconds, setSeconds] = useState(60);

    useEffect(() => {
        if (errorCode === 503) {
            const interval = setInterval(() => {
                setSeconds(prevSeconds => {
                    if (prevSeconds === 1) {
                        clearInterval(interval);
                        window.location.reload();
                    }
                    return prevSeconds - 1;
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [errorCode]);

    if (errorCode == 503) {
        return (
            <div className="Noaccess ErrorBox">
                <h3>HIRO Statistics</h3>
                <p>The system is currently initializing.</p>
                <p>I will retry in {seconds} seconds</p>
            </div>
        )
    }

    return (
        <div className="Noaccess ErrorBox">
            <h3>HIRO Statistics</h3>
            <p>You are not allowed to access this page!</p>
            <p>Please contact <a href={`mailto:${conf.HIRO_REQUEST_EMAIL}`} >{conf.HIRO_REQUEST_EMAIL}</a> to gain access.</p>
        </div>
    )
}

export default CriticalError;
