
const svgShadowFilter = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
    const defs = svg.append('defs')
        .append('filter')

    const shadowFilter = defs.append('filter')
        .attr('id', 'shadow')
        .attr('x', '0%')
        .attr('y', '0%')
        .attr('width', '100%')
        .attr('height', '100%');

    shadowFilter.append('feOffset')
        .attr('dx', 1)
        .attr('dy', 1)
        .attr('input', 'SourceAlpha');

    shadowFilter.append('feGaussianBlur')
        .attr('stdDeviation', 6)
        .attr('result', 'blur');

    shadowFilter.append('feFlood')
        .attr('flood-opacity', '.6')
        .attr('result', 'color');

    shadowFilter.append('feComposite')
        .attr('operator', 'out')
        .attr('in', 'SourceGraphic')
        .attr('in2', 'blur');

    shadowFilter.append('feComposite')
        .attr('operator', 'in')
        .attr('in', 'color');

    shadowFilter.append('feComposite')
        .attr('operator', 'in')
        .attr('in', 'SourceGraphic');
}

export default svgShadowFilter;