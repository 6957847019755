import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";

const HeaderMenu: React.FC = () => {

    const [isOpen, setIsOpen] = useState(false);

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { sendMessage, registerEventListener, unregisterEventListener } = newLocal;
    
    useEffect(() => {
        const el = registerEventListener("clickInMain", ()=>{handleMasterClick()})

        return (() => {
            unregisterEventListener("clickInMain", el)
        })
    }, [])

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        sendMessage("openNavi", !isOpen);
    };

    const handleMasterClick = useCallback(() => {
        sendMessage("openNavi", false);
        setIsOpen(false)
    }, [isOpen]);

    return (
        <div className="headerMenuIcon">
            <div className={` burger-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
            </div>
        </div>
    );
}
export default HeaderMenu;