import { useContext, useEffect, useState } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";
import { AccountWithProfile } from "@hiro-graph/client";


const HeaderUser: React.FC = () => {

    const [myName, setMyName] = useState("Unknown");
    const [myMail, setMyMail] = useState("Unknown");
    const [myAvatar, setMyAvatar] = useState("Unknown");
    const [isOpen, setIsOpen] = useState(false);

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { graph, storage } = newLocal;
    const conf = storage.get("config");

    useEffect(() => {
        try {

            graph.me().then((res: AccountWithProfile) => {
                const cName = res.profile["ogit/Auth/Account/displayName"] || "?";
                setMyName(cName);
                setMyAvatar(res.avatar)
                setMyMail(res.account["ogit/email"]);
            }).catch(() => {
                console.log("Failed to fetch me()");
            })
        } catch {
            console.log("No graph")
        }
    }, [])

    if (myName == "Unknown") {
        return (<></>)
    }

    return (
        <div className="headerUserBlock" onClick={() => { setIsOpen(!!!isOpen) }}>
            <div className="headerUserName">{myName}</div>
            <div className="headerUserAvatar"><img height={36} width={36} src={myAvatar} /></div>
            {isOpen && (
                <div className="headerDetails">
                    <div className="headerDetailTopLine">
                        <div className="headerOrgTitle">{conf.HIRO_COMPANY_FULL_NAME }</div>
                        <div className="headerLogout">Abmelden</div>
                    </div>
                    <div className="headerDetailBottomLine">
                        <div className="headerDetailAvatar"><img height={100} width={100} src={myAvatar} /></div>
                        <div className="headerDetailInfo">
                            <div className="headerDetailInfoName">{myName}</div>
                            <div>{myMail}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )

}
export default HeaderUser;

