import React, { ChangeEvent, useContext, useState } from "react";
import { GlobalContext, GlobalContextProps } from "../../globalProvider";
import TimeSequenceElement from "./TimeSequenceElement";
import { HistKeys } from "../../dataStruct/HistData";

export type SequenceButton = {
    id: number;
    name: string;
    sequence: HistKeys;
    format: string;
}

export const buttonList: SequenceButton[] = [
    {
        id: 0,
        name: 'Monat',
        sequence: 'month',
        format: 'YYYY-MM'

    },
 /*   {
        id: 1,
        name: 'Woche',
        sequence: 'weeks',
        format: 'YYYY-W'
    },*/
    {
        id: 2,
        name: 'Tag',
        sequence: 'days',
        format: 'YYYY-MM-DD'
    },
    {
        id: 3,
        name: 'Jahr',
        sequence: 'year',
        format: 'YYYY'
    }
];

const TimeSequence: React.FC = () => {
    const [selectedSequence, setSelectedSequence] = useState(buttonList[0]);

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { sendMessage } = newLocal;
   
    const handleClick = (evt: ChangeEvent<HTMLSelectElement>) => {
        const newSel: number = evt.target.value as unknown as number;
        if (newSel != selectedSequence.id) {
            const seq = buttonList.filter(ele=>ele.id==newSel)[0]
            setSelectedSequence(seq);
            sendMessage("sequenceSelected",seq)
        }      
    }

    return (
        <div className="timespanContainer">
            <div>Zeitsequenz:</div>
            <div>
                <select className="timeSelector" onChange={ handleClick} > 
                    {buttonList.map((tb) => (
                        <TimeSequenceElement
                            tb={tb}
                            key={tb.id}
                        />
                    ))}
                </select>
            </div>
        </div>
    );
}

export default TimeSequence;
