

const HeaderTitle: React.FC = () => {
    return (
        <div className="headerTitle hideonphone">
        <div className="logo"></div>
        HIRO Statistics
        </div>
    )

}
export default HeaderTitle;