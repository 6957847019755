import { faTimesCircle, faQuestionCircle as faQuestionCircleFull } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";

import './infoTrigger.scss';

interface InfoTriggerProps {
    id: string
}

const InfoTrigger: React.FC<InfoTriggerProps> = ({ id }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isOpen, setIsOpen] = useState(false)

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { registerEventListener, unregisterEventListener, sendMessage } = newLocal;


    const handleInfoEvent = (selId: string) => {
        if (selId == id) {
            setIsSelected(true)
            setIsOpen(true)
        } else {
            setIsSelected(false)
            setIsOpen(false)
        }
    }

    useEffect(() => {
        if (isOpen) {
            sendMessage("showInfoWindow", id)
        }
    }, [isOpen])

    const activateInfoWindow = () => {
        if (isOpen) {
            sendMessage("showInfoWindow", "none")
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }

    }

    useEffect(() => {
        const el = registerEventListener("showInfoWindow", handleInfoEvent)

        return (() => {
            unregisterEventListener("showInfoWindow", el)
        })
    }, [])

    let myIcon = <FontAwesomeIcon icon={faQuestionCircle} className="infoTriggerIcon default" />;

    if (isHovered) myIcon = <FontAwesomeIcon icon={faQuestionCircleFull} className="infoTriggerIcon hover" />
    if (isSelected) myIcon = <FontAwesomeIcon icon={faTimesCircle} className="infoTriggerIcon active" />



    return (
        <span className="infoTrigger" onMouseEnter={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} onClick={activateInfoWindow}>
            {myIcon}
        </span>
    )
}

export default InfoTrigger;