
import { useContext } from "react";
import { DGunit } from "../../../dataStruct/DGUnit";
import { GlobalContextProps, GlobalContext } from "../../../globalProvider";
import KIBarDGAll from "./KIBarDGAll";
import KnowledgeBarDGAll from "./KnowledgeBarDGAll";
import MultiplyBarDGAll from "./MultiplyBarDGAll";
import ReuseBarDGAll from "./ReuseBarDGAll";
import UserBarDGAll from "./UserBarDGAll";

type DGAllHumanBoxProps = {
    dgUnit: DGunit
}

const DGAllHumanBox: React.FC<DGAllHumanBoxProps> = ({dgUnit}) => {
       

    if (!dgUnit || dgUnit.statistic.user.ammount.all == 0) {
        return <></>;
    }

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { graph, storage } = newLocal;
    const conf = storage.get("config");

    return (<div className="DGAllBox" >
        <div className="DGAllBoxTitle">{conf.HIRO_COMPANY_FULL_NAME}</div>
        <div className="DGAllBoxContent">
            <div className="DGHumanAllSector">
                <UserBarDGAll
                    unit={dgUnit}
                />
            </div>
            <div className="DGHumanAllSector">
                <KIBarDGAll
                    unit={dgUnit}
                />
            </div>
            <div className="DGHumanAllSector">
                <KnowledgeBarDGAll
                    unit={dgUnit}
                />
            </div>
            <div className="DGHumanAllSector">
                <MultiplyBarDGAll
                    unit={dgUnit}
                />
            </div>
            <div className="DGHumanAllSector">
                <ReuseBarDGAll
                    unit={dgUnit}
                />
            </div>
        </div>
    </div>)
}

export default DGAllHumanBox;