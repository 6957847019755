export type displayType = {
    display: string,
    short: string,
    id: number
}

export const displayTypes: displayType[] = [
    { display: "Normale Ansicht", id: 0, short: "norm" },
    { display: "Minimale Ansicht", id: 1, short: "clean" },
    { display: "Volle Ansicht", id: 2, short: "full" }
]
