
import { UnitButton } from "../../naviContainer/UnitSelector";
import DGAllBarMasterBox from "./DGAllBarMasterBox";

import DGAllTickerMasterBox from "./DGAllTickerMasterBox";
import GaugeMasterBox from "./GaugeMasterBox";

type AutoUnitBoxProps = {
    unit: UnitButton
    maxTask: number
    maxAutoTickets: number;
    maxFactor: number;
    maxUser: number;
}
const AutoUnitBox: React.FC<AutoUnitBoxProps> = ({unit,maxTask,maxAutoTickets,maxUser,maxFactor}) => {

    return (
        <div className="UnitBox AutoUnitBox">
            <div className="UnitBoxTitle">{unit.name}</div>
            
            <div className="UnitBoxContent AutoUnitBoxContent">
                <div className="UnitSector AutoUnitSector">
                    <DGAllTickerMasterBox
                        dgUnit={unit.DGunit}
                        maxTickets={maxTask}
                    />
                </div>
                <div className="UnitSector AutoUnitSector">
                    <DGAllBarMasterBox
                        dgUnit={unit.DGunit}
                        maxAutoTickets={maxAutoTickets}
                        maxUser={maxUser}
                    />
                </div>
                <div className="UnitSector AutoUnitSector">
                    <GaugeMasterBox
                        unit={unit}
                        maxFactor={maxFactor}
                    />
                </div>
            
            </div>
            
        </div>
    )
}

export default AutoUnitBox;