
import { DGunit } from "../../../dataStruct/DGUnit";
import CustomBar from "./CustomBar";



interface DGAllBarMasterBoxProps {
    dgUnit: DGunit;
    maxUser: number;
    maxAutoTickets: number;
}

const ticketsPerUser = 5;
export const pricePerTicket = 1.22;


const DGAllBarMasterBox: React.FC<DGAllBarMasterBoxProps> = ({ dgUnit, maxAutoTickets, maxUser }) => {
    
    if (!dgUnit) return (<div>Keine Unitdaten</div>)

    //FORMEL: Virtuelle Menschen nötig = Menschen real + (Tickets automatisiert / Konstante [Tickets pro user] )
    const maxVirtVal = Math.round(maxUser + maxAutoTickets / ticketsPerUser);
    const virtVal = Math.round(dgUnit.statistic.user.ammount.all + (dgUnit.statistic.taskInfo.full + dgUnit.statistic.taskInfo.part) / ticketsPerUser);
    const pricePerVirt = Math.round((dgUnit.statistic.user.ammount.all == 0 ? 0 : pricePerTicket * (dgUnit.statistic.user.ammount.all + (dgUnit.statistic.taskInfo.full + dgUnit.statistic.taskInfo.part) / ticketsPerUser) / dgUnit.statistic.user.ammount.all) * 100) / 100;


    return (
        <div className="DGAllTicketMasterBox">
            <CustomBar
                leftVal={dgUnit.statistic.user.ammount.all}
                leftMax={maxUser}
                leftLabel={"User beteiligt"}
                rightVal={virtVal}
                rightMax={maxVirtVal}
                rightLabel={"Ohne Hiro wären es"}    
            />
            <CustomBar
                leftVal={pricePerTicket}
                leftMax={pricePerTicket}
                leftLabel={"Kosten pro Ticket (€)"}
                rightVal={pricePerVirt}
                rightMax={pricePerVirt}
                rightLabel={"Kosten ohne HIRO"}    
            />
           
        </div>
    )
}

export default DGAllBarMasterBox;