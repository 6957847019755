import { useContext, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

import cx from 'classnames';

import './FilterContainer.scss'
import TimespanSelector from './TimespanSelector';
import UnitSelector from './UnitSelector';
import DisplaySelector from './DisplaySelector';
import { GlobalContextProps, GlobalContext } from '../../globalProvider';
import SortSelector from './SortSelector';
import TimeSequence from './TimeSequence';
import HistorySwitch from './HistorySwitch';
import DownloadStatistic from './DownloadStatistic';


const FilterContainer: React.FC = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [isResponsiveOpen, setIsResponsiveOpen] = useState(false);
    const outerDivRef = useRef<HTMLDivElement>(null);
    const [leftPosition, setLeftPosition] = useState(0);

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { registerEventListener, unregisterEventListener, sendMessage, selectedModule } = newLocal;

    const getRelativeLeft = (element: HTMLElement | null, accumulator = 0): number => {
        if (!element) return accumulator;
        return getRelativeLeft(element.offsetParent as HTMLElement, accumulator + element.offsetLeft);
    };

    const updatePosition = () => {
        if (outerDivRef.current) {
            const relativeLeft = getRelativeLeft(outerDivRef.current) + outerDivRef.current.offsetWidth;
            setLeftPosition(relativeLeft);
        }
    };

    const showResponsive = (b: boolean) => {
        setIsResponsiveOpen(b);
    }

    useEffect(() => {
        sendMessage("naviResize", {})
    }, [leftPosition])

    useEffect(() => {
        window.addEventListener('resize', updatePosition);
        window.addEventListener('scroll', updatePosition, true);
        const el = registerEventListener('openNavi', showResponsive);
        updatePosition();

        return () => {
            window.removeEventListener('resize', updatePosition);
            window.removeEventListener('scroll', updatePosition, true);
            unregisterEventListener("openNavi", el)
        };
    }, []);


    useEffect(() => {

        const observerCallback: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
            // Bypass buggy frame handling
            window.requestAnimationFrame((): void | undefined => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                updatePosition();
            });
        };
        const resizeObserver = new ResizeObserver(observerCallback);

        if (outerDivRef.current) {
            resizeObserver.observe(outerDivRef.current);
        }

        return () => {
            if (outerDivRef.current) {
                resizeObserver.unobserve(outerDivRef.current);
            }
        };
    }, []);

    return (
        <>
            <div ref={outerDivRef} className={cx('naviWrapper', { 'naviMightBeOpen': isResponsiveOpen, 'naviMightBeClosed': !isResponsiveOpen, 'naviMin': !isOpen, 'naviMax': isOpen })}>
                <div className="naviContainer">
                    <DisplaySelector />
                </div>
                {
                    (selectedModule.id == "History") && (
                        <>
                            <div className="naviContainer">
                                <TimeSequence />
                            </div>
                            <div className="naviContainer">
                                <HistorySwitch />
                            </div>
                        </>
                    )
                }
                {
                    (selectedModule.id != "History") && (
                        <div className="naviContainer">
                            <SortSelector />
                        </div>
                    )}
                <div className="naviContainer">
                    <UnitSelector />
                </div>
                {
                    (selectedModule.id == "History") && (
                        <div className="naviContainer">
                            <DownloadStatistic />
                        </div>
                    )
                }
                <div className={cx('flap', { 'flapMin': !isOpen, 'flapMax': isOpen })} style={{ left: `${leftPosition}px` }} onClick={() => { setIsOpen(!isOpen) }}>
                    {isOpen ? (
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    ) : (
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                    )}
                </div>
            </div>
        </>
    )
}

export default FilterContainer;