import React, { useContext, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { GlobalContextProps, GlobalContext } from '../../../globalProvider';

type WiederverwendungsBarProps = {
    rate: number;
}

const WiederverwendungsBar: React.FC<WiederverwendungsBarProps> = ({ rate }) => {
    
    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { selectedDisplay, lastUpdate } = newLocal;

    const d3Container = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [myRate, setMyRate] = useState(0);

    useEffect(() => {
        let isMounted = true;
        if (d3Container.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    window.requestAnimationFrame(() => {
                        if (isMounted) {
                            setContainerWidth(entry.contentRect.width);
                            setContainerHeight(entry.contentRect.height);
                        }
                    });

                }
            });

            resizeObserver.observe(d3Container.current);
            return () => {
                isMounted = false;
                resizeObserver.disconnect();
            };
        }
    }, [d3Container]);

    useEffect(() => {
        if (containerWidth > 0 && containerHeight > 0) {
            const data: number = rate;
            const maxValue = 100;

          

            const xScale = d3.scaleLinear()
                .domain([0, maxValue])
                .range([0, containerWidth]);

            d3.select(d3Container.current).selectAll("*").remove();

            const svg = d3.select(d3Container.current).append('svg')
                .attr('width', containerWidth)
                .attr('height', containerHeight);


            let cumulativeWidth = 0;


            svg.selectAll('rect')
                .data([data])
                .enter().append('rect')
                .attr('x', d => {
                    const x = cumulativeWidth;
                    cumulativeWidth += xScale(d);
                    return x;
                })
                .attr('y', 0) // Konstante y-Position
                .attr('width', d => xScale(d))
                .attr('class', 'unitcompare reuse DGUnit');
        }
    }, [containerWidth, containerHeight, lastUpdate]);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };


    let dTask = "";

    if (selectedDisplay.short == "norm") {
        dTask = myRate.toString() + "%";
    }

    if (selectedDisplay.short == "full") {
        dTask = myRate.toString() + "%";
    }

    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <div className="UnitSectorTitle">
                <div className="UnitSectorTitleText">Wiederverwendung</div>
                <div className="UnitSectorTitleProc">{dTask}</div>
            </div>
            <div className="UnitBarBackground" ref={d3Container}></div>
            <div className='SectorWrapper'>
                {isHovered && (
                    <div className="tooltip">
                        <div className='tootipLine sum'>
                            <div className='toolTiptext'>Wiederverwendung</div>
                            <div className='toolTipvalue '>{myRate}%</div>
                        </div>
                    </div>)
                }
            </div>
        </div>
    )
}

export default WiederverwendungsBar;

