import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";
import fixedPositionWidth from "../helper/fixedPositionWidth";
import { UnitButton } from "../naviContainer/UnitSelector";
import cx from 'classnames';
import DGAllAutomationBox from "./Automatisierungsergebnis/DGAllAutomationBox";
import AutoUnitBox from "./Automatisierungsergebnis/AutoUnitBox";
import AutomationLegende from "./Automatisierungsergebnis/AutomationLegende";


export const reactionTimeHiro = 22;
export const reactionTimeHuman = 600;

const Automationsergebnis: React.FC = () => {

    const parentRef = useRef<HTMLDivElement>(null);
    const width = fixedPositionWidth(parentRef);

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { storage, meta, registerEventListener, unregisterEventListener, selectedModule, selectedType } = newLocal;

    const [isOpen, setIsOpen] = useState(true);
    const [allUnit, setAllUnit] = useState<UnitButton>();
    const [maxTasks, setMaxTasks] = useState(0);
    const [maxAutoTasks, setMaxAutoTasks] = useState(0);
    const [maxUser, setMaxUser] = useState(0);
    const [maxFactor, setMaxFactor] = useState(0);
    const [sort, setSort] = useState<((a: UnitButton, b: UnitButton) => number) | null>(() => selectedModule.currentSort?.sortFunc || null);


    const forceResize = () => {
        window.setTimeout(() => {
            //Synthetic Event to trigger resize in case the FilterBox is resized
            const event = new Event('resize');
            window.dispatchEvent(event);
        }, 20)
    }

    useEffect(() => {
        if (!storage.has("unitList")) {
            return;
        }
        const allUnits = storage.get("unitList") as UnitButton[];
        const myUnit = JSON.parse(JSON.stringify(allUnits[0])) as UnitButton; // Clone the Helper Element  
        let newMaxTask = 0;
        let newMaxAutoTask = 0;
        let newMaxFactor = 0;
        let mU = 0;
        myUnit.meta = []

        const allSelected = allUnits[0].isSelected;

        allUnits.forEach((unit, key) => {
            if (key == 0) {
                return;
            }

            if (selectedType.short != "all" && unit.DGunit.type != selectedType.short) {
                return;
            }

            if (!allSelected && !unit.isSelected) {
                return;
            }

            const stat = unit.DGunit.statistic;
            const mTask = stat.taskInfo.full + stat.taskInfo.part + stat.taskInfo.not + stat.taskInfo.assist;
            const aTask = stat.taskInfo.full + stat.taskInfo.part + stat.taskInfo.assist;

            const rFactor = (reactionTimeHuman / reactionTimeHiro) * aTask;
            unit.meta = rFactor;
            myUnit.meta.push(rFactor);

            myUnit.DGunit.statistic.taskInfo.full += stat.taskInfo.full;
            myUnit.DGunit.statistic.taskInfo.not += stat.taskInfo.not;
            myUnit.DGunit.statistic.taskInfo.assist += stat.taskInfo.assist;
            myUnit.DGunit.statistic.taskInfo.part += stat.taskInfo.part;

            myUnit.DGunit.statistic.user.ammount.sme += stat.user.ammount.sme;
            myUnit.DGunit.statistic.user.ammount.ke += stat.user.ammount.ke;
            myUnit.DGunit.statistic.user.ammount.both += stat.user.ammount.both;
            myUnit.DGunit.statistic.user.ammount.all += stat.user.ammount.all;

            newMaxTask = Math.max(newMaxTask, mTask);
            newMaxAutoTask = Math.max(newMaxAutoTask, aTask);
            newMaxFactor = Math.max(newMaxFactor, rFactor);
            mU = Math.max(mU, stat.user.ammount.all);
        })

        setAllUnit(myUnit);
        setMaxTasks(newMaxTask);
        setMaxAutoTasks(newMaxAutoTask)
        setMaxFactor(newMaxFactor);
        setMaxUser(mU);
        forceResize();

    }, [storage, sort, selectedType])



    useEffect(() => {
        const list = registerEventListener("naviResize", forceResize)
        const sortListener = registerEventListener("changeSort", (ele) => {
            setSort(() => ele.sortFunc)
        });

        return (() => {
            unregisterEventListener("naviResize", list)
            unregisterEventListener("changeSort", sortListener)
        })

    }, [])

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    if (!storage.has("unitList") || !allUnit) {
        return (<div>No Units</div>)
    }

    const myUnits = storage.get("unitList") as UnitButton[];
    const showAll = myUnits[0].isSelected;

    let sFunc = (a: UnitButton, b: UnitButton) => { return 0 }
    if (sort) sFunc = sort

    const conf = storage.get("config");

    return (
        <div className="allUnitContainer" ref={parentRef}>
            <div className={cx("DGAllWrapperBox autoWrapper", [{ "minimized": !isOpen }])}>
                <div className={cx("DGAllWrapperFixed", [{ "minimized": !isOpen }])} style={{ width: width }}>
                    <div className="DGAllSeperator " onClick={handleClick}>
                        {
                            isOpen && (<div className="minimizeHeaderFlap"><FontAwesomeIcon icon={faAngleUp} /></div>)
                        }
                        {
                            !isOpen && (<div className="minimizeHeaderFlap"><FontAwesomeIcon icon={faAngleDown} /></div>)
                        }

                    </div>
                    {isOpen && (<>
                        <div className="DGMainFixedcontainer automationHeight">
                            <DGAllAutomationBox
                                unit={allUnit}
                                maxTask={maxTasks}
                                maxAutoTickets={maxAutoTasks}
                                maxUser={maxUser}
                            />
                        </div>
                        <div className="DGLegendFixContainer">
                            <AutomationLegende />
                        </div>
                    </>)}
                    {!isOpen && (
                        <div className="DGMinimizedFixed"> {conf.HIRO_COMPANY_FULL_NAME} | alle Daten</div>
                    )}
                    <div className="DGAllSeperator"></div>
                </div>
            </div>
            <div className="unitWrapper">
                {
                    [...myUnits]
                        .sort(sFunc)
                        .map((ele: UnitButton, position: number) => {
                            if (ele.id == 0) {
                                return;
                            }
                            if (selectedType.short != "all" && ele.DGunit.type != selectedType.short) {
                                return;
                            }

                            // show only selected or all
                            if (showAll || ele.isSelected) {
                                return (
                                    <AutoUnitBox
                                        key={ele.id}
                                        unit={ele}
                                        maxTask={maxTasks}
                                        maxAutoTickets={maxAutoTasks}
                                        maxUser={maxUser}
                                        maxFactor={maxFactor}
                                    />
                                )
                            }

                            return;

                        })}
            </div>
        </div>
    )
}

export default Automationsergebnis;