import { reactionTimeHiro, reactionTimeHuman } from "../Automationsergebnis";
import { pricePerTicket } from "./DGAllBarMasterBox";

const AutomationLegende: React.FC = ({ }) => {

    return (
        <>
            <div className="legendBox">
                <div className="legendTitle">Tickets:</div>
                <div className="legendLines">
                    <div className="legendColorBox first"></div>
                    <div className="legendText">Vollautomatisiert</div>
                </div>
                <div className="legendLines">
                    <div className="legendColorBox second"></div>
                    <div className="legendText">Teilautomatisiert</div>
                </div>
                <div className="legendLines">
                    <div className="legendColorBox third"></div>
                    <div className="legendText">Nicht automatisiert</div>
                </div>
                <div className="Box">
                    <div>Ø Bearbeitungszeit Mensch<br></br> pro Ticket - <strong>{reactionTimeHuman}s</strong></div>
                    <div>Ø Bearbeitungszeit Hiro<br></br> pro Ticket - <strong>{reactionTimeHiro}s</strong></div>
                    <div>Preis eines Tickets - <strong>{pricePerTicket}€</strong></div>
                </div> 
            </div>
        </>
    )
}

export default AutomationLegende;
