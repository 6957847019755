import { UnitButton } from "../../naviContainer/UnitSelector";
import GaugeBox from "./GaugeBox";

interface GaugeMasterBoxProps {
    unit: UnitButton;
    maxFactor: number;
}

const GaugeMasterBox: React.FC<GaugeMasterBoxProps> = ({unit,maxFactor }) => {


    return (
        <div className="gaugeMasterContainer">
            <GaugeBox
                maxVal={maxFactor}
                val={unit.meta ?? 0}
                
            />
        </div>
    )
}

export default GaugeMasterBox;