import { SequenceButton } from "./TimeSequence";

interface TimeButtonElementProps {
    tb: SequenceButton;
}

const TimeSequenceElement: React.FC<TimeButtonElementProps> = ({ tb }) => {
    return (
        <option className="timeOption" value={tb.id}>
            {tb.name}
        </option>
    );
}

export default TimeSequenceElement;
