import { Button } from "@hiro-ui/bootstrap";
import { useContext, useState } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";
import { UnitButton } from "./UnitSelector";
import cx from 'classnames';


interface UnitButtonElementProps {
    ub: UnitButton;
}

const UnitButtonElement: React.FC<UnitButtonElementProps> = ({ ub }) => {

    const [showTooltip, setShowTooltip] = useState(false);

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { sendMessage,selectedType } = newLocal;

    const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
        sendMessage("activateUnit", ub.id);
    }

    const isDisabled = (ub.DGunit.type != selectedType.short) && ub.DGunit.type!='all' && selectedType.short!='all';
    

    return (
        <div className="tbe">
            <Button
                className={cx("unitButton", { "unitButtonSelected": ub.isSelected })}
                onClick={handleClick}
                onMouseOver={() => setShowTooltip(true)}
                onMouseOut={() => setShowTooltip(false)}
                disabled={isDisabled}

            >
                <div className="unitButtonText">
                    {ub.DGunit.short}
                </div>
            </Button>
            {showTooltip && <span className="unittooltip">{ub.DGunit.name}</span>}

        </div>
    );
}

export default UnitButtonElement;
