import * as d3 from 'd3';
import { useEffect, useState } from "react";
import { containerData } from "./DGAllKnowledgeBox";
import React from 'react';
import { waterfallBar } from '../KnowledgeTransfer';

interface svgBoxProps {
    root: d3.Selection<any, any, any, any> | null,
    container: containerData,
    data: waterfallBar,
    count: number,
}

const barHeight: number = 30;
const connectorHeight: number = 30;

export const svgBox = ({ root, container, data, count }: svgBoxProps) => {



    const handleMouseOver = (id: string) => {

        root?.select(`#label-${count + 1}`)
            .style('visibility', "hidden")


        root?.select(`#blur-${count}`)
            .raise()
            .style('visibility', "visible")

        root?.select(`#grp-${count}`)
            .raise()


        const d3element = root?.select(`#connector-${id}`);
        const element = d3element?.node() as SVGGraphicsElement | null;

        if (element && d3element) {
            d3element
                .raise()
        }

        root?.select(`#clabel-${id}`)
            .raise()
            .style('visibility', "visible")
    }

    const handleMouseOut = (id: string) => {
        const d3element = root?.select(`#connector-${id}`);
        const element = d3element?.node() as SVGGraphicsElement | null;

        if (element && d3element) {
            d3element
                .raise()

            root?.select(`#label-${count + 1}`)
                .style('visibility', "visible")

            root?.select(`#blur-${count}`)
                .raise()
                .style('visibility', "hidden")

            root?.select(`#clabel-${id}`)
                .raise()
                .style('visibility', "hidden")

            //alle nachliegenden Gruppen raisen
            for (let i = count; i >= 0; i--) {
                root?.select(`#grp-${i}`)
                    .lower()
            }


        }
    }

    if (!root) {
        console.log(count)
        return <React.Fragment key={count}></React.Fragment>
    }

    const g = root
        .append('g')
        .attr('transform', `translate(0, ${((barHeight + connectorHeight) * count) + 30})`)
        .attr('id', `grp-${count}`)

    //Modal background if focussed
    g
        .append("rect")
        .attr('x', 0)
        .attr('y', `${(((barHeight + connectorHeight) * count) + 30) * -1}`)
        .attr('height', container.height)
        .attr('width', container.width)
        .attr("id", `blur-${count}`)
        .style('visibility', "hidden")
        .style('pointer-events', 'none')
        .attr('class', 'waterfall modalbackground')

    const scale = d3.scaleLinear()
        .domain([0, data.maxVal])
        .range([0, container.width]);

    g
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', scale(data.value))
        .attr('height', barHeight)
        .attr('filter', 'url(#drop-shadow)')
        .attr('class', 'waterfall knowledgeBar');

    // Texte 
    g
        .append('text')
        .attr('transform', `translate(3, -5)`)
        .style('pointer-events', 'none')
        .attr('id', `label-${count}`)
        .attr('class', 'waterfall labelText')
        .text(data.label)

    g
        .append('text')
        .attr('x', container.width)
        .attr('transform', `translate(-4, -4)`)
        .style('pointer-events', 'none')
        .attr('class', 'waterfall valueText')
        .text(data.value)

    //Connectoren

    data.flowConnectors.forEach((c, k) => {
        const scaleTarget = d3.scaleLinear()
            .domain([0, c.postmax])
            .range([0, container.width]);

        const clazz = 'waterfall connector background ' + (c.markSufficientBorder ? 'insufficient' : '')

        g.
            append('path')
            .attr('d', `
            M ${scale(c.start)},${barHeight}
            L ${scaleTarget(c.poststart)},${barHeight + connectorHeight}
            L ${scaleTarget(c.postend)},${barHeight + connectorHeight}
            L ${scale(c.end)},${barHeight}
            Z
            `)
            .attr('id', `connector-${count}-${k}`)
            .attr('class', clazz)
            .on('mouseover', () => {
                handleMouseOver(`${count}-${k}`)
            })
            .on('mouseout', () => {
                handleMouseOut(`${count}-${k}`)
            });

        g
            .append('text')
            .attr('id', `clabel-${count}-${k}`)
            .attr('transform', `translate(3, ${((barHeight + connectorHeight)) - 4})`)
            .style('pointer-events', 'none')
            .style('visibility', "hidden")
            .attr('class', 'waterfall connector labelText')
            .text(c.label)
    })

    return <React.Fragment key={count}></React.Fragment>
}