import './Header.scss'
import HeaderMenu from './HeaderMenu';
import HeaderPageTitle from './HeaderPageTitle';
import HeaderTitle from './HeaderTitle';
import HeaderUser from './HeaderUser';
const HeaderContainer: React.FC = () => {

    
    return (
        <div className="headerContainer">
            <HeaderMenu />
            <HeaderTitle />
            <HeaderPageTitle />
            <HeaderUser />
        </div>
    )

}
export default HeaderContainer;