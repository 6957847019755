import React, { useContext, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { GlobalContext, GlobalContextProps } from '../../../globalProvider';
import InfoTrigger from '../../infoWindow/InfoTrigger';
import { DGunit } from '../../../dataStruct/DGUnit';

type UserBarProps = {
    unit: DGunit;
}

const UserBarDGAll: React.FC<UserBarProps> = ({ unit }) => {
    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { selectedDisplay, lastUpdate } = newLocal;

    const d3Container = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        let isMounted = true;
        if (d3Container.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    window.requestAnimationFrame(() => {
                        if (isMounted) {
                            setContainerWidth(entry.contentRect.width);
                            setContainerHeight(entry.contentRect.height);
                        }
                    });

                }
            });

            resizeObserver.observe(d3Container.current);
            return () => {
                isMounted = false;
                resizeObserver.disconnect();
            };
        }
    }, [d3Container]);

    const data = [
        { idx: 0, value1: unit.statistic.user.ammount.both, value2: unit.statistic.user.ammount.sme, value3: unit.statistic.user.ammount.ke }
        
    ];

    useEffect(() => {
        if (containerWidth > 0 && containerHeight > 0) {
            d3.select(d3Container.current).selectAll("*").remove();

            const svg = d3.select(d3Container.current)
                .append('svg')
                .attr('fill', 'rgba(0,100,200,1)')
                .attr('width', containerWidth)
                .attr('height', containerHeight);

            const xScale = d3.scaleLinear()
                .domain([0, unit.statistic.user.ammount.all ?? 0])
                .range([0, containerWidth]);

            const yScale = d3.scaleBand()
                .domain(data.map((_, i) => i.toString()))
                .range([0, containerHeight])

            const stack = d3.stack()
                .keys(['value1', 'value2', 'value3']);

            const stackedData = stack(data);

            svg.append('g')
                .append('rect')
                .attr('y', 0)
                .attr('x', 0)
                .attr('width', xScale(unit.statistic.user.ammount.all ?? 0))
                .attr('height', containerHeight)
                .attr('class', 'human svgUserRect all ')
                

            stackedData.forEach((layer, index) => {
                svg.append('g')
                    .selectAll(`rect`)
                    .data(layer)
                    .join('rect')
                    .attr('y', d => yScale(d.data.idx.toString()) ?? 0)
                    .attr('x', d => xScale(d[0]))
                    .attr('width', d => xScale(d[1]) - xScale(d[0]))
                    .attr('height', yScale.bandwidth())
                    .attr('class', d => {
                        if (index == 0) {
                            return 'human svgUserRect all sum'
                        }
                        if (index == 1) {    
                            return 'human svgUserRect all sme'
                        }
                        return  'human svgUserRect all ke'
                    });
            });
        }

    }, [containerWidth, containerHeight, lastUpdate]);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    const mdata = unit.statistic.user.ammount.all;


    let dTask = (<></>);

    if (selectedDisplay.short == "norm") {
        dTask = (<div>{mdata.toString()}</div>)
    }

    if (selectedDisplay.short == "full") {
        dTask = (<div className='fullTextLine DGHumanTitleText'>
            <div className='all'>{unit.statistic.user.ammount.all}</div>
            <div className='both'>{unit.statistic.user.ammount.both}</div>
            <div className='sme'>{unit.statistic.user.ammount.sme}</div>
            <div className='ke'>{unit.statistic.user.ammount.ke}</div>
        </div>)
    }

    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <div className="UnitSectorTitle">
                <div className="UnitSectorTitleText">Nutzer <InfoTrigger id="humanUser" /></div>
                <div className="UnitSectorTitleProc">{dTask}</div>
            </div>
            <div className="UnitBarBackgroundDGAll" ref={d3Container}></div>
            <div className='SectorWrapper'>
                {isHovered && (
                    <div className="tooltip">
                        <div className='tootipLine DGHumanTitleText all'>
                            <div className='toolTiptext'>Nutzer gesamt</div>
                            <div className='toolTipvalue '>{unit.statistic.user.ammount.all}</div>
                        </div>
                        <div className='tootipLine DGHumanTitleText both'>
                            <div className='toolTiptext'>SME + KE</div>
                            <div className='toolTipvalue '>{unit.statistic.user.ammount.both}</div>
                        </div>
                        <div className='tootipLine DGHumanTitleText sme'>
                            <div className='toolTiptext'>SME</div>
                            <div className='toolTipvalue '>{unit.statistic.user.ammount.sme}</div>
                        </div>
                        <div className='tootipLine DGHumanTitleText ke'>
                            <div className='toolTiptext'>KE</div>
                            <div className='toolTipvalue '>{unit.statistic.user.ammount.ke}</div>
                        </div>
                        
                    </div>
                )}

            </div>
        </div>
    )
}

export default UserBarDGAll;

