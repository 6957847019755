import React, { useContext, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { GlobalContext, GlobalContextProps } from '../../../globalProvider';
import svgShadowFilter from '../../helper/svgShadowFilter';
import { DGunit } from '../../../dataStruct/DGUnit';

type ReuseBarBarProps = {
    unit: DGunit;
}

const ReuseBar: React.FC<ReuseBarBarProps> = ({ unit }) => {

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { selectedDisplay, lastUpdate } = newLocal;

    const d3Container = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [isHovered, setIsHovered] = useState(false);


    useEffect(() => {
        let isMounted = true;
        if (d3Container.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    window.requestAnimationFrame(() => {
                        if (isMounted) {
                            setContainerWidth(entry.contentRect.width);
                            setContainerHeight(entry.contentRect.height);
                        }
                    });

                }
            });

            resizeObserver.observe(d3Container.current);
            return () => {
                isMounted = false;
                resizeObserver.disconnect();
            };
        }
    }, [d3Container]);

    useEffect(() => {
        if (containerWidth > 0 && containerHeight > 0) {
            const mean = 0

            const sd = 0;
            const min = 0;
            const max = 0;

            d3.select(d3Container.current).selectAll("*").remove();

            const svg = d3.select(d3Container.current).append('svg')
                .attr('width', containerWidth)
                .attr('height', containerHeight);

            let x = d3.scaleLinear()
                .domain([0, 100])
                .range([0, containerWidth]);

            svgShadowFilter(svg);

            //Min bis Max
            svg
                .append('rect')
                .attr('filter', 'url(#shadow)')
                .attr('x', x(min))
                .attr('y', 0)
                .attr('width', x(max) - x(min))
                .attr('class', 'human DGAll shadow')

            svg
                .append('rect')
                .attr('x', x(Math.max(mean - sd, 0)))
                .attr('y', 0)
                .attr('width', x(Math.min(mean + sd, 100)) - x(Math.max(mean - sd, 0)))
                .attr('class', 'human DGAll standard')

            //STD-Abweichung
            svg
                .append('rect')
                .attr('filter', 'url(#shadow)')
                .attr('x', x(Math.max(mean - sd, 0)))
                .attr('y', 0)
                .attr('width', x(Math.min(mean + sd, 100)) - x(Math.max(mean - sd, 0)))
                .attr('class', 'human DGAll shadow')
            svg
                .append('rect')
                .attr('x', x(Math.max(mean - sd, 0)))
                .attr('y', 0)
                .attr('width', x(Math.min(mean + sd, 100)) - x(Math.max(mean - sd, 0)))
                .attr('class', 'human DGAll standardShadow')

            svg.append('line')
                .attr('x1', x(mean))
                .attr('x2', x(mean))
                .attr('y1', 3)
                .attr('y2', 27)
                .attr('class', 'human DGAll meanLine')

        }
    }, [containerWidth, containerHeight, lastUpdate]);

    const handleMouseOver = () => {
        setIsHovered(true);
    };


    const handleMouseOut = () => {
        setIsHovered(false);
    };

    let dTask = "";

    if (selectedDisplay.short == "norm") {
        dTask = "0 %";
    }

    if (selectedDisplay.short == "full") {
        dTask = "0 %";
    }


    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <div className="UnitSectorTitle">
                <div className="UnitSectorTitleText">Wiederverwendungsfaktor</div>
                <div className="UnitSectorTitleProc">{dTask}</div>
            </div>
            <div className="UnitBarBackground" ref={d3Container}></div>
            <div className='SectorWrapper'>
                {isHovered && (
                    <div className="tooltip">
                        <div className='tootipLine sum'>
                            <div className='toolTiptext'>Wiederverwendungsfaktor</div>
                            <div className='toolTipvalue '>{dTask}</div>
                        </div>
                    </div>)
                }
            </div>
        </div>
    )
}

export default ReuseBar;

