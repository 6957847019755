import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import * as d3 from 'd3';
import { GlobalContextProps, GlobalContext } from "../../../globalProvider";
import { allUnitType } from "../Historie";
import InfoTrigger from "../../infoWindow/InfoTrigger";
import { HistGroupType, HistType } from "../../../dataStruct/HistData";

type DGAllHistoryBoxProps = {
    data: allUnitType
}

type hDataType = { [key in allDisTypes]: HistoryFormat[] }

export type HistoryFormat = {
    date: string,
    value: {
        [key: string]: number
    },
    lineVal: number
}

export type HistoryFormatSess = {
    date: string,
    value: number,
    lineVal: number
}

export interface HistoryUnitBoxHandle {
    getInnerData: () => any;
}

export type allDisTypes = "tasks" | "kis" | "sessions";


const DGAllHistoryBox = forwardRef<HistoryUnitBoxHandle, DGAllHistoryBoxProps>(({ data }, ref) => {
    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { selectedSequence, selectedHistory, storage } = newLocal;
    const config = storage.get("config");

    const d3Container = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [maxTask, setMaxTask] = useState(0);
    const [maxKi, setMaxKis] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [innerData, setInnerData] = useState<HistoryFormat[]>([]);
    const [helperData, setHelperData] = useState<hDataType | []>([]);
    const [sessData, setSessData] = useState<{ [key: string]: number }>({});

    const sessDataRef = useRef(sessData);
    const helperDataRef = useRef(helperData);

    useEffect(() => {
        helperDataRef.current = helperData;
    }, [helperData]);

    useEffect(() => {
        sessDataRef.current = sessData;
    }, [sessData]);

    useImperativeHandle(ref, () => ({
        getInnerData: () => {
            //convert data
            const help: any = {}
            const hd = helperDataRef.current as hDataType;

            for (let dtype of ["kis", "tasks"]) {
                help[dtype] = hd[dtype as allDisTypes].reduce((acc, obj) => {
                    acc[obj.date] = obj.value;
                    return acc;
                }, {} as { [key: string]: { [key: string]: number } })
            }

            help["sessions"] = sessDataRef.current;

            return { data: help, name: config.HIRO_COMPANY_FULL_NAME, isAll: true }
        },
    }));


    useEffect(() => {
        let isMounted = true;
        if (d3Container.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    window.requestAnimationFrame(() => {
                        if (isMounted) {
                            setContainerWidth(entry.contentRect.width);
                            setContainerHeight(entry.contentRect.height);
                        }
                    });
                }
            });

            resizeObserver.observe(d3Container.current);
            return () => {
                isMounted = false;
                resizeObserver.disconnect();
            };
        }
    }, [d3Container]);

    useEffect(() => {
        //Prepare Data
        const formatDate = (date: Date, format: string) => {
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const week = Math.floor((date.getTime() - new Date(year, 0, 1).getTime()) / (7 * 24 * 60 * 60 * 1000)) + 1; // Berechnet die Wochennummer

            switch (format) {
                case "YYYY":
                    return `${year}`;
                case "YYYY-MM":
                    return `${year}-${month.toString().padStart(2, '0')}`;
                case "YYYY-MM-DD":
                    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                case "YYYY-W":
                    return `${year}-W${week.toString().padStart(2, '0')}`;
                default:
                    return date.toISOString().substring(0, 10);
            }
        }

        const getStartForWeeks = (weekString: string) => {
            const [year, week] = weekString.split('-W').map(Number);
            const januaryFirst = new Date(year, 0, 1);
            const daysToAdd = (1 - januaryFirst.getDay()) + 7 * (week - 1);

            if (januaryFirst.getDay() === 0) {
                januaryFirst.setDate(2);
            } else if (januaryFirst.getDay() > 1) {
                januaryFirst.setDate(januaryFirst.getDate() + (8 - januaryFirst.getDay()));
            }
            januaryFirst.setDate(januaryFirst.getDate() + daysToAdd);

            return januaryFirst;
        }

        const generateMonthSequence = (startDate: string, format: string) => {
            let start = new Date();
            if (format === "YYYY-W") {
                start = getStartForWeeks(startDate);
            } else {
                start = new Date(startDate);
            }
            const today: Date = new Date();
            let end;

            switch (format) {
                case "YYYY":
                    end = new Date(today.getFullYear() + 1, 0, 1);
                    break;
                case "YYYY-MM":
                    end = new Date(today.getFullYear(), today.getMonth() + 1, 1);
                    break;
                case "YYYY-MM-DD":
                    end = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                    break;
                case "YYYY-W":
                    const currentWeek = Math.floor((today.getTime() - new Date(today.getFullYear(), 0, 1).getTime()) / (7 * 24 * 60 * 60 * 1000));
                    end = new Date(today.getFullYear(), 0, 1 + currentWeek * 7);
                    break;
                default:
                    end = today;
                    break;
            }

            const result: HistoryFormat[] = [];

            while (start <= end) {
                result.push({
                    date: formatDate(start, format),
                    value: {},
                    lineVal: 0
                });

                switch (format) {
                    case "YYYY":
                        start.setFullYear(start.getFullYear() + 1);
                        break;
                    case "YYYY-MM":
                        start.setMonth(start.getMonth() + 1);
                        break;
                    case "YYYY-MM-DD":
                        start.setDate(start.getDate() + 1);
                        break;
                    case "YYYY-W":
                        start.setDate(start.getDate() + 7);
                        break;
                }
            }

            return result;
        }

        const allkey = [];
        for (let key in data) {
            allkey.push(Object.keys(data[key][selectedHistory as allDisTypes]).sort()[0]);
        }

        const min = allkey.sort()[0];

        const helpData: { [key in allDisTypes]: HistoryFormat[] } = {
            kis: generateMonthSequence(min, selectedSequence.format),
            sessions: generateMonthSequence(min, selectedSequence.format),
            tasks: generateMonthSequence(min, selectedSequence.format)
        }

        for (let key in data) {
            for (let dtype of ["kis", "tasks"]) {
                for (let tick in data[key][dtype as allDisTypes]) {
                    const nv = data[key][dtype as allDisTypes][tick];
                    helpData[dtype as allDisTypes].forEach((d) => {
                        if (d.date === tick) {
                            for (let k in nv) {
                                const idx: HistType = k as HistType;
                                const nn = nv as HistGroupType;
                                if (nn !== undefined) {
                                    const helper = nn[idx] || 0;
                                    if (helper !== undefined)
                                        d.value[idx] = (d.value[idx] || 0) + helper;
                                }
                            }
                        }
                    });
                }
            }
        }

        let newMaxTask = 0;
        let newMaxKis = 0;
        for (let dtype of ["kis", "tasks"]) {
            helpData[dtype as allDisTypes].forEach((d: HistoryFormat) => {
                if (dtype === "tasks") {
                    const auto = d.value["full"] || 0 + d.value["part"] || 0 + d.value["assist"] || 0;
                    const all = auto + d.value["not"] || 0;
                    if (all > 0) {
                        d.lineVal = Math.round(auto / all * 100);
                    }

                    newMaxTask = Math.max(d.value["sum"], newMaxTask);
                } else {
                    const all = (d.value["undeployed"] || 0) + (d.value["default"] || 0) + (d.value["validation"] || 0);
                    newMaxKis = Math.max(all, newMaxKis);
                }

            });
        }
        const newData = helpData[selectedHistory as allDisTypes];
        // handle sessions
        const sessA: { [key: string]: number } = {};
        for (let key in data) {
            for (let tick in data[key]["sessions"]) {
                const nv = data[key]["sessions"][tick];
                sessA[tick] = (sessA?.[tick] ?? 0) + (nv as any as number);
            }

        }

        setMaxTask(Math.round(newMaxTask * 1.3));
        setMaxKis(Math.round(newMaxKis * 1.3));
        setSessData(sessA);
        setHelperData(helpData);
        setInnerData(newData);

    }, [data, selectedHistory, selectedSequence]);

    const reduceArrayElements = (data: string[], maxElements: number) => {
        if (data.length <= maxElements) return data;

        const reducedArray = [];
        const step = Math.ceil((data.length) / (maxElements));

        let i = 0;
        for (i = 0; i < data.length; i += step) {
            reducedArray.push(data[i]);
        }

        if (i >= data.length) {
            reducedArray.pop();
            reducedArray.push(data[data.length - 1]);
        }

        return reducedArray;
    }


    useEffect(() => {
        if (containerWidth > 0 && containerHeight > 0) {
            d3.select(d3Container.current).selectAll("*").remove();

            const svg = d3.select(d3Container.current).append('svg')
                .attr('width', containerWidth)
                .attr('height', containerHeight);

            const drawWidth = containerWidth - 50;

            const xScale = d3.scaleBand()
                .domain(innerData.map((d => d.date)))
                .range([35, drawWidth])
                .padding(0.1);

            const drawHeight = containerHeight - 30;
            const valBorder = (selectedHistory === "tasks") ? maxTask : maxKi;
            const filteredTicks = reduceArrayElements(innerData.map((d => d.date)), 13);

            const yScale = d3.scaleLinear()
                .domain([0, valBorder])
                .range([0, drawHeight]);

            const yScaleR = d3.scaleLinear()
                .domain([0, valBorder])
                .range([drawHeight, 0]);

            const yLineScale = d3.scaleLinear()
                .domain([0, 100])
                .range([0, drawHeight]);

            const yLineScaleR = d3.scaleLinear()
                .domain([0, 100])
                .range([drawHeight, 0]);

            let path: [number, number][] = [];
            const lineGenerator = d3.line()
                .x(d => d[0])
                .y(d => d[1])
                .curve(d3.curveMonotoneX);
            path.push([35, drawHeight]);

            const drawG = svg.append("g");
            drawG.attr('transform', `translate(10,10)`);

            const tooltip =
                drawG
                    .append("g")
                    .style("display", "none")
                    .raise();

            tooltip.append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("height", 100)
                .attr("width", 200)
                .attr("class", "tooltipbox");

            innerData.forEach((d) => {
                const x = xScale(d.date) || 0;
                const g = drawG.append("g");

                const no = (selectedHistory === "tasks") ? d.value["not"] || 0 : d.value["undeployed"] || 0;
                const baseClass = "historyBlock " + ((selectedHistory === "tasks") ? "tasks" : "kis");

                g
                    .append("rect")
                    .attr("x", x)
                    .attr("y", drawHeight - yScale(no))
                    .attr("height", yScale(no))
                    .attr("width", xScale.bandwidth())
                    .attr("class", baseClass + " first");

                const pa = (selectedHistory === "tasks") ? d.value["part"] || 0 : d.value["validation"] || 0;

                g
                    .append("rect")
                    .attr("x", x)
                    .attr("y", drawHeight - yScale(no) - yScale(pa))
                    .attr("height", yScale(pa))
                    .attr("width", xScale.bandwidth())
                    .attr("class", baseClass + " second");

                //Inserting new assissted state

                let as = 0;
                if (selectedHistory === "tasks") {

                    as = d.value["assist"] || 0;
                    g
                        .append("rect")
                        .attr("x", x)
                        .attr("y", drawHeight - yScale(no) - yScale(pa) - yScale(as))
                        .attr("height", yScale(as))
                        .attr("width", xScale.bandwidth())
                        .attr("class", baseClass + " fourth");
                }

                const fu = (selectedHistory === "tasks") ? d.value["full"] || 0 : d.value["default"] || 0;

                g
                    .append("rect")
                    .attr("x", x)
                    .attr("y", drawHeight - yScale(no) - yScale(pa) - yScale(fu) - yScale(as))
                    .attr("height", yScale(fu))
                    .attr("width", xScale.bandwidth())
                    .attr("class", baseClass + " third");

                path.push([(x + (xScale.bandwidth() / 2)), (drawHeight - yLineScale(d.lineVal))]);

                const setTooltipText = (toolTexts: { [key: string]: string | number }[]) => {
                    tooltip.selectAll("text").remove();
                    let yCount = 10;
                    let count = 0;
                    Object.values(toolTexts).forEach((txt) => {
                        count++;
                        const [[key, value]] = Object.entries(txt);
                        tooltip
                            .append("text")
                            .text(key)
                            .attr("x", "10")
                            .attr("y", yCount)
                            .attr("dy", "1em")
                            .attr("class", "tooltiptext key item_" + count);
                        tooltip
                            .append("text")
                            .text(value)
                            .attr("x", 100)
                            .attr("y", yCount)
                            .attr("dy", "1em")
                            .attr("class", "tooltiptext value item_" + count);
                        yCount += 20;
                    });

                };

                const handleMouseEnter = () => {

                    let xPos = x - 250;
                    if (xPos < drawWidth / 2 - 30) {
                        xPos = x + 150;
                    }

                    tooltip
                        .raise()
                        .style("display", "")
                        .attr("transform", `translate(${xPos},${drawHeight / 2 - 25})`);

                    let disp: { [key: string]: string | number; }[] = [];
                    disp.push({ "Zeit": d.date });

                    Object.entries(d.value).forEach((ele) => {
                        const k: any = {};
                        if (ele[0] === "ignore") return;
                        k[ele[0]] = ele[1];
                        disp.push(k);
                    });

                    setTooltipText(disp);
                };

                const handleMouseLeave = () => {
                    tooltip
                        .style("display", "none");
                };

                g
                    .on('mouseenter', handleMouseEnter)
                    .on('mouseleave', handleMouseLeave);

            });

            path.push([containerWidth - 49, path[path.length - 1][1]]);

            drawG.append('path')
                .datum(path)
                .attr('stroke', 'rgba(255,128,128,1)')
                .attr('stroke-width', 2)
                .attr('fill', 'none')
                .attr("d", lineGenerator);

            svg.append("g")
                .attr("transform", `translate(10,${containerHeight - 20})`)
                .call(
                    d3.axisBottom(xScale)
                        .tickFormat(d => {
                            if (filteredTicks.includes(d)) {
                                return d;
                            }
                            return "";
                        })
                        .tickSizeOuter(0)
                )
                .selectAll(".tick")
                .style("opacity", (d, i, nodes) => {

                    const tickLabel = d3.select(nodes[i]).select("text").text();
                    return tickLabel === "" ? 0 : 1;
                });
            const formatAxis = d3.format(".2s");

            svg.append("g")
                .attr("transform", `translate(35,10)`)
                .call(
                    d3.axisLeft(yScaleR)
                        .tickFormat(d => formatAxis(d).replace('G', 'B'))
                        .tickSizeOuter(0)
                );

            const rAxis = svg.append<SVGGElement>("g")
                .attr("transform", `translate(${containerWidth - 27},10)`)
                .call(d3.axisLeft(yLineScaleR).tickSizeOuter(0));

            rAxis
                .selectAll(".tick line")
                .attr("transform", "translate(6,0)");

            rAxis
                .selectAll(".tick text")
                .style("text-anchor", "start")
                .attr("dx", "18px");
        }

    }, [containerWidth, containerHeight, innerData]);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    const allUnit = useState();

    if (!allUnit) {
        return <></>;
    }


    const conf = storage.get("config");

    return (
        <div className="DGAllBox">
            <div className="DGAllBoxTitle">{conf.HIRO_COMPANY_FULL_NAME}</div>
            <div className="DGAllBoxContent">
                <div className="DGAllSector overWriteDGAllSector">
                    <div>
                        <div className="UnitSectorTitle">
                            {(selectedHistory === "tasks") &&
                                <div className="UnitSectorTitleText">Tickets <InfoTrigger id="unitcompareTickets" /></div>
                            }
                            {(selectedHistory === "kis") &&
                                <div className="UnitSectorTitleText">Kis <InfoTrigger id="unitcompareKis" /></div>
                            }
                        </div>
                        <div className="HistoryBarBackgroundDGAll" ref={d3Container}></div>
                    </div>
                </div>
            </div>

        </div>
    )
})

export default DGAllHistoryBox;
