
import { DGunit } from "../../../dataStruct/DGUnit";
import KIBar from "./KIBar";
import KnowledgeBar from "./KnowledgeBar";
import MultiplyBar from "./MultiplyBar";
import ReuseBar from "./ReuseBar";
import UserBar from "./UserBar";

type UnitBoxProps = {
    unit: DGunit,
    maxUser: number,
    maxKis: number,
    maxFactor: number
}


const UnitBox: React.FC<UnitBoxProps> = ({ unit,maxUser,maxKis,maxFactor }) => {
    

    return (
        <div className="UnitBox">
            <div className="UnitBoxTitle">{unit.name}</div>
            <div className="UnitBoxContent">
                <div className="UnitSectorHuman">
                    <UserBar
                        unit={unit}
                        maxUser={maxUser}
                    />
                </div>
                <div className="UnitSectorHuman">
                    <KnowledgeBar
                        unit={unit}
                    />
                </div>
                <div className="UnitSectorHuman">
                    <KIBar
                        unit={unit}
                        maxKis={maxKis}
                    />
                </div>
                <div className="UnitSectorHuman">
                    <MultiplyBar
                        unit={unit}
                        maxFactor={maxFactor}
                    />
                </div>
                <div className="UnitSectorHuman">
                    <ReuseBar
                        unit={unit}
                    />
                </div>
                
            </div>
            
        </div>
    )
}

export default UnitBox;