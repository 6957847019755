import React, { ChangeEvent, useContext, useState } from "react";
import { GlobalContext, GlobalContextProps} from "../../globalProvider";
import DisplayButtonElement from "./DisplayButtonElement";
import { displayTypes, displayType } from "../../dataStruct/Displays";


const DisplaySelector: React.FC = () => {
    const [selectedDisplay, setSelectedDisplay] = useState(0);

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { sendMessage } = newLocal;

    const handleClick = (evt: ChangeEvent<HTMLSelectElement>) => {
        const newSel: number = evt.target.value as unknown as number;
        if (newSel != selectedDisplay) {
            sendMessage("displaySelected", newSel);
            setSelectedDisplay(newSel);
        }
    }

    return (
        <div className="timespanContainer">
            <div>Anzeige:</div>
            <div>
                <select className="timeSelector" onChange={handleClick} >
                    {displayTypes.map((ele: displayType) => {
                        return <DisplayButtonElement
                            dt={ele}
                            key={ele.id}
                        />
                    })}
                </select>
            </div>
        </div>
    );
}

export default DisplaySelector;
