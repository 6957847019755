
import AutomationCircle from "./AutomationCircle";
import InfoTrigger from '../../infoWindow/InfoTrigger';
import { DGunit } from "../../../dataStruct/DGUnit";

interface AutomationRateContainerProps {
    dgUnit: DGunit;  
}

const AutomationRateContainer: React.FC<AutomationRateContainerProps> = ({ dgUnit }) => {


    const t = dgUnit.statistic.taskInfo;
    const sum = Math.max(t.full + t.not + t.part,1);

    const fullRate = 100 * t.full / sum;
    const partRate = 100 * t.part / sum;
    const allRate = 100 * (t.full + t.part) / sum;

    return (
        <div className="DGAllAutomationRateContainer">
            <div className="UnitSectorTitle">
                <div className="UnitSectorTitleText">Automatisierungsrate <InfoTrigger id="automationAutomationRate" /></div>
            </div>
            <div className="AutomationCircleSector">
                <AutomationCircle
                    title="Voll"
                    id ="full"
                    value={fullRate}
                />
                <AutomationCircle
                    title="Teil"
                    id="part"
                    value={partRate}
                />
                <AutomationCircle
                    title="Gesamt"
                    id="all"
                    value={allRate}
                />
                
            </div>
        </div>
    )
}

export default AutomationRateContainer;