import * as d3 from 'd3';
import { useContext, useRef, useState, useEffect } from "react";
import { GlobalContextProps, GlobalContext } from "../../../globalProvider";


const barHeight = 60;

export type CustomBarProps = {
    leftVal: number,
    leftMax: number,
    leftLabel: string,
    rightVal: number,
    rightMax: number,
    rightLabel: string,
}

const CustomBar: React.FC<CustomBarProps> = (data) => {

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { selectedDisplay, lastUpdate } = newLocal;

    const d3Container = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        let isMounted = true;
        if (d3Container.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    window.requestAnimationFrame(() => {
                        if (isMounted) {
                            setContainerWidth(entry.contentRect.width);
                            setContainerHeight(entry.contentRect.height);
                        }
                    });

                }
            });

            resizeObserver.observe(d3Container.current);
            return () => {
                isMounted = false;
                resizeObserver.disconnect();
            };
        }
    }, [d3Container]);

    useEffect(() => {
        if (containerWidth > 0 && containerHeight > 0) {
            const half = containerWidth / 2;


            const leftScale = d3.scaleLinear()
                .domain([0, data.leftMax])
                .range([0, half]);

            const rightScale = d3.scaleLinear()
                .domain([0, data.rightMax])
                .range([0, half]);

            d3.select(d3Container.current).selectAll("*").remove();

            const svg = d3.select(d3Container.current).append('svg')
                .attr('width', containerWidth)
                .attr('height', containerHeight);


            //Filter

            const defs = svg.append("defs")
            const filter = defs.append("filter")
                .attr("id", "shadowBack")
                .attr("filterUnits", "userSpaceOnUse")

            filter.append("feOffset")
                .attr("dy", "5")
                .attr("input", "SourceAlpha")

            filter.append("feGaussianBlur")
                .attr("stdDeviation", "8")
                .attr("result", "blur")

            filter.append("feFlood")
                .attr("flood-opacity", "0.314")
                .attr("result", "color")

            filter.append("feComposite")
                .attr("operator", "out")
                .attr("in", "SourceGraphic")
                .attr("in2", "blur")

            filter.append("feComposite")
                .attr("operator", "in")
                .attr("in", "color")

            filter.append("feComposite")
                .attr("operator", "in")
                .attr("in2", "SourceGraphic")


            // Background left

            svg.append("rect")
                .attr("x", "0")
                .attr("y", "0")
                .attr("width", half)
                .attr("height", barHeight)
                .attr("class", "leftBarBackground");

            // Background right
            svg.append("path")
                .attr("d", `M${half},0H${containerWidth}V${barHeight}H${half}Z`)
                .attr("class", "rightBarBackground")

            svg.append("path")
                .attr("d", `M${half},0H${containerWidth}V${barHeight}H${half}Z`)
                .attr("filter", "url(#shadowBack)")
                .attr("fill", "#fff")

            // left bar

            svg.append("rect")
                .attr("x", "0")
                .attr("y", "0")
                .attr("width", leftScale(data.leftVal))
                .attr("height", barHeight)
                .attr("class", "leftBarValue");


            svg.append("path")
                .attr("d", `M${half},0H${half + rightScale(data.rightVal)}V${barHeight}H${half}Z`)
                .attr("class", "rightBarValue")

            svg.append("path")
                .attr("d", `M${half},0H${half + rightScale(data.rightVal)}V${barHeight}H${half}Z`)
                .attr("filter", "url(#shadowBack)")
                .attr("fill", "#fff")
        }
    }, [containerWidth, containerHeight, lastUpdate]);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    const mTasks = 10;

    let dTask = (<></>);

    if (selectedDisplay.short == "norm") {
        dTask = (<div>{mTasks.toString()}</div>)
    }

    if (selectedDisplay.short == "full") {
        dTask = <div className='fullTextLine'>
            <div className='sum'>{mTasks.toString()}</div>
            <div className="first">
                {10}
            </div>
            <div className="second">
                {20}
            </div>
            <div className="third">
                {30}
            </div>

        </div>
    }

    return (
        <div
            
            className='BarBox'
        >
            <div className="DGALLBarBack" ref={d3Container}></div>
            <div className="BarLabelBox">
                <div className="leftTextContainer">
                    <div className="labeltext">{data.leftLabel}</div>
                    <div className="valuetext">{data.leftVal}</div>
                </div>
                <div className="rightTextContainer">
                    <div className="labeltext">{data.rightLabel}</div>
                    <div className="valuetext">{data.rightVal}</div>
                </div>
            </div>
            
        </div>
    )
}

export default CustomBar;