import { displayType } from "../../dataStruct/Displays";

interface DisplayButtonElementProps {
    dt: displayType;
}

const DisplayButtonElement: React.FC<DisplayButtonElementProps> = ({ dt }) => {
    return (
        <option className="timeOption" value={dt.id}>
            {dt.display}
        </option>
    );
}

export default DisplayButtonElement;
