import { useContext, useEffect, useState } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";


const HeaderPageTitle: React.FC = () => {

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { registerEventListener, unregisterEventListener, selectedModule } = newLocal;

    const [headerTitle, setHeaderTitle] = useState(selectedModule.desc);

    const switchModule = (ele:any) => {
        setHeaderTitle(ele.desc);
    }


    useEffect(() => {
        const el = registerEventListener("switchDisplayModule", switchModule)
        return (() => {
            unregisterEventListener("switchDisplayModule", el)
        })
    }, [])


    return (
        <div className="headerTitle">
            {headerTitle }
        </div>
    )

}
export default HeaderPageTitle;