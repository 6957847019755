import { Button } from "@hiro-ui/bootstrap";
import { useContext, useState } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";
import cx from 'classnames';
import { unitType } from "../../dataStruct/UnitTypes";

interface UnitTypeButtonProps {
    type: unitType
}

const UnitTypeButton: React.FC<UnitTypeButtonProps> = ({ type }) => {

    const [showTooltip, setShowTooltip] = useState(false);

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { sendMessage, selectedType } = newLocal;

    const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
        sendMessage("switchUnitType",type)
    }

    return (
        <div className="tbe">
            <Button
                className={cx("unitButton", { "unitButtonSelected": (type.id == selectedType.id) })}
                onClick={handleClick}
                onMouseOver={() => setShowTooltip(true)}
                onMouseOut={() => setShowTooltip(false)}

            >
                <div className="unitButtonText">
                    {type.display}
                </div>
            </Button>
            {showTooltip && <span className="unittooltip">
                {type.long}
            </span>}

        </div>
    );
}

export default UnitTypeButton;
