
export type unitType = {
    id: number,
    display: string,
    long: string,
    short: string
}

export const unitTypes: unitType[] = [
    {
        id: 0, short: "all", display: "Alle", long: "Alle units"
    },
    {
        id: 1, short: "DEV", display: "Dev", long: "Entwicklungsunits"
    },
    {
        id: 2, short: "PROD", display: "Prod", long: "Produktive Units"
    }
]
