import Client from '@hiro-graph/client';
import React, { createContext, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ReactComponent as SVGAutomation } from './components/modules/SVG/AutomationResult.svg';

//Mockdata for developement
// TODO: remove
import mockdata from './mock/getAllUnit.json';

import Automationsergebnis from './components/modules/Automationsergebnis';
import { UnitButton } from './components/naviContainer/UnitSelector';
import { DGunit } from './dataStruct/DGUnit';
import { responseMetaType, responseType } from './dataStruct/GraphData';
import { hookComponent, knownModules } from './dataStruct/Modules';
import { displayType, displayTypes } from './dataStruct/Displays';
import { unitType, unitTypes } from './dataStruct/UnitTypes';
import { buttonList, SequenceButton } from './components/naviContainer/TimeSequence';
import { historyDisplay } from './components/naviContainer/HistorySwitch';
import { HistData } from './dataStruct/HistData';


export interface GlobalProviderProps {
    children: ReactNode;
    graph: Client;
    errorHandler: React.Dispatch<React.SetStateAction<number>>;
    initHandler: React.Dispatch<React.SetStateAction<boolean>>;
    config: any
}

export interface GlobalContextProps {
    graph: Client;
    storage: Map<string, any>;
    selectedModule: hookComponent;
    selectedDisplay: displayType;
    selectedType: unitType;
    selectedSequence: SequenceButton;
    selectedHistory: string;
    allUnits: DGunit[];
    histData: HistData | undefined;
    meta: responseMetaType;
    lastUpdate: number;
    updateStorage: (key: string, value: any) => void;
    registerEventListener: (channel: string, callback: cbFunction) => number;
    sendMessage: (channel: string, message: any) => void;
    unregisterEventListener: (channel: string, listenerId: number) => void;
}

type cbFunction = (arg: any) => void;
type Listener = {
    id: number;
    callback: cbFunction;
};
type eventListenerType = Record<string, Listener[]>;

export type sortElement = {
    id: string,
    display: string;
    sortFunc: (a: UnitButton, b: UnitButton) => number
}

const searchParams = new URLSearchParams(window.location.search);
const showAutomation = searchParams.get('showAutomation');

if (showAutomation === 'true') {
    knownModules.push({
        id: "Automation",
        desc: "Automationsergebnis",
        icon: SVGAutomation,
        hook: Automationsergebnis,
        currentSort: null,
        sortList: [{
            id: "0",
            display: "Volle Automatisierung",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const autoa = a.DGunit.statistic.taskInfo.full;
                const autob = b.DGunit.statistic.taskInfo.full;

                if (autoa > autob) return -1;
                if (autoa < autob) return 1;
                return 0;
            },
        }, {
            id: "1",
            display: "Name",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;
                if (a.DGunit.name < b.DGunit.name) return 1;
                if (a.DGunit.name < b.DGunit.name) return -1;
                return 0;
            },
        }]

    })
}


export const GlobalContext = createContext<GlobalContextProps | null>(null);
export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children, graph, errorHandler, initHandler, config }) => {

    const [selectedModule, setSelectedModule] = useState<hookComponent>(knownModules[0])
    const [selectedDisplay, setSelectedDisplay] = useState<displayType>(displayTypes[0])
    const [selectedUnitType, setselectedUnitType] = useState<unitType>(unitTypes[2])
    const [selectedSequenceType, setselectedSequenceType] = useState<SequenceButton>(buttonList[0])
    const [selectedHistory, setselectedHistory] = useState<string>(historyDisplay[0])


    const [eventListeners, setEventListeners] = useState<eventListenerType>({});
    const [storage, setStorage] = useState(new Map<string, any>());
    const [lastDataTime, setLastDataTime] = useState(0);

    const [allUnits, setAllUnits] = useState<DGunit[]>([]);
    const [histData, setHistData] = useState<HistData | undefined>();
    const [meta, setMeta] = useState<responseMetaType>({
        "transfer": {
            "min": 0,
            "max": 0,
            "mean": 0,
            "std": 0
        },
        "kis": {
            "min": 0,
            "max": 0,
            "mean": 0,
            "std": 0
        },
        multifactor: {
            "min": 0,
            "max": 0,
            "mean": 0,
            "std": 0
        }
    })

    const eventListenersRef = useRef(eventListeners);
    const nextListenerId = useRef(0);

    const updateStorage = (key: string, value: any) => {
        setStorage(new Map(storage.set(key, value)));
    };

    const switchModule = (newmodule: hookComponent) => {
        knownModules.forEach(mod => {
            if (mod.id == newmodule.id) {
                setSelectedModule(mod)
            }
        })
    }

    const switchType = (newType: unitType) => {
        setselectedUnitType(newType)
    }

    const switchDisplayType = (display: number) => {
        displayTypes.forEach((dt: displayType) => {
            if (dt.id == display) {
                setSelectedDisplay(dt);
            }
        })
    }

    useEffect(() => {
        updateStorage("config", config)
    }, [])

    const registerEventListener = useCallback((channel: string, callback: cbFunction) => {
        const listenerId = nextListenerId.current++;
        setEventListeners(prevListeners => ({
            ...prevListeners,
            [channel]: [
                ...(prevListeners[channel] || []),
                { id: listenerId, callback }
            ]
        }));
        return listenerId;
    }, []);

    const unregisterEventListener = useCallback((channel: string, listenerId: number) => {
        setEventListeners(prevListeners => ({
            ...prevListeners,
            [channel]: (prevListeners[channel] || []).filter(listener => listener.id !== listenerId)
        }));
    }, []);

    useEffect(() => {
        eventListenersRef.current = eventListeners;
    }, [eventListeners]);

    const sendMessage = useCallback((channel: string, message: any) => {
        const listeners = eventListenersRef.current[channel];
        if (listeners) {
            listeners.forEach(listener => listener.callback(message));
        }
    }, [eventListeners]);

    const dataHandler = (alldata: responseType) => {
        const data = alldata.data;
        const meta = alldata.meta;
        const fetchedUnits: DGunit[] = [];
        Object.keys(data).forEach(key => {
            fetchedUnits.push({
                hiroId: data[key]["id"] as string,
                name: data[key]["name"] as string,
                short: data[key]["shortname"] as string,
                type: data[key]["type"] as string,
                statistic: data[key]["statistic"]
            })
        });
        fetchedUnits.sort((a, b) => {
            if (a.name.toLocaleLowerCase() == b.name.toLocaleLowerCase()) return 0;
            return (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1);
        })
        setAllUnits(fetchedUnits);
        setMeta(meta);
        errorHandler(0)
    }

    //Time handling
    useEffect(() => {
        registerEventListener("timeSelected", setNewTime)
    }, [])

    const setNewTime = (timespan: number) => {
        const epUrl = config.HIRO_STATISTIC_ENDPOINT as string + "/getAllUnitsTimed?timespan=" + timespan;
        if (!graph.token) {
            console.log("Mock data blocks timeselection?")
            return;
        }

        graph.token.get()
            .then((token: string) => {
                axios
                    .get(epUrl,
                        {
                            headers: {
                                'Authorization': `Bearer ${token}` // Füge den Bearer-Token als Authorization-Header hinzu
                            }
                        })
                    .then(response => {
                        const alldata = response.data as responseType;
                        dataHandler(alldata)
                        setLastDataTime(timespan)
                    }).catch((e: any) => {
                        console.log(e)
                    })
            })
            .catch(error => {
                console.log(error)
            })
    }

    const newSequence = (seq: SequenceButton) => {
        setselectedSequenceType(seq);
    }

    const newHistory = (hist: string) => {
        setselectedHistory(hist);
    }

   

    useEffect(() => {
        const histUrl = config.HIRO_STATISTIC_ENDPOINT as string + "/getAllUnitsSequenced";

        if (!graph.token) {
            console.log("Mock data blocks timeselection!")
            return;
        }

        graph.token.get()
            .then((token: string) => {
                axios
                    .get(histUrl,
                        {
                            headers: {
                                'Authorization': `Bearer ${token}` // Füge den Bearer-Token als Authorization-Header hinzu
                            }
                        })
                    .then(response => {
                        const alldata = response.data as HistData;
                        setHistData(alldata)
                    }).catch((e: any) => {
                        console.log(e)
                    })
            })
            .catch(error => {
                console.log(error)
            })
    }, [])   

    //Here the magic happens
    useEffect(() => {
        const doMock = config.HIRO_STATISTIC_MOCKDATA == "true";
        const epUrl = config.HIRO_STATISTIC_ENDPOINT as string + "/getAllUnits"
        if (!graph.token) {
            const alldata = mockdata as responseType;
            dataHandler(alldata);
            initHandler(false);
            return;
        }

        graph.token.get()
            .then(token => {
                axios
                    .get(epUrl,
                        {
                            headers: {
                                'Authorization': `Bearer ${token}` // Füge den Bearer-Token als Authorization-Header hinzu
                            }
                        })
                    .then(response => {
                        const alldata = doMock ? mockdata as responseType : response.data as responseType;
                        dataHandler(alldata)
                        initHandler(false);
                    })
                    .catch(error => {
                        if (doMock) {
                            const alldata = mockdata as responseType;
                            dataHandler(alldata)
                            initHandler(false);
                        } else {
                            initHandler(false);
                            errorHandler(error.response.status)
                        }
                    });

            }).catch(e => {
                initHandler(false);
                errorHandler(500)
                console.log(e)
            })

    }, [])

    useEffect(() => {
        registerEventListener("switchDisplayModule", switchModule)
        registerEventListener("switchUnitType", switchType)
        registerEventListener("displaySelected", switchDisplayType)
        registerEventListener("sequenceSelected", newSequence)
        registerEventListener("historySelected", newHistory)
    }, [])

    const defaultValue: GlobalContextProps = {
        graph: graph,
        storage: storage,
        selectedModule: selectedModule,
        selectedDisplay: selectedDisplay,
        selectedType: selectedUnitType,
        selectedSequence: selectedSequenceType,
        selectedHistory: selectedHistory,
        allUnits: allUnits,
        histData: histData,
        meta: meta,
        lastUpdate: lastDataTime,
        updateStorage: updateStorage,
        registerEventListener: registerEventListener,
        sendMessage: sendMessage,
        unregisterEventListener: unregisterEventListener
    }

    return (
        <GlobalContext.Provider value={defaultValue}>
            {children}
        </GlobalContext.Provider>
    );
};
