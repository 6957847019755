import React, { useContext, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { GlobalContextProps, GlobalContext } from '../../../globalProvider';
import InfoTrigger from '../../infoWindow/InfoTrigger';




type KiItem = {
    deployed: number;
    undeployed: number;
    validating: number;
}

type KIBarProps = {
    maxKi: number;
    kis: KiItem
}

const KIBarDGAll: React.FC<KIBarProps> = ({ kis, maxKi }) => {

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { selectedDisplay, lastUpdate } = newLocal;

    const d3Container = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        let isMounted = true;
        if (d3Container.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    window.requestAnimationFrame(() => {
                        if (isMounted) {
                            setContainerWidth(entry.contentRect.width);
                            setContainerHeight(entry.contentRect.height);
                        }
                    });

                }
            });

            resizeObserver.observe(d3Container.current);
            return () => {
                isMounted = false;
                resizeObserver.disconnect();
            };
        }
    }, [d3Container]);

    useEffect(() => {
        if (containerWidth > 0 && containerHeight > 0) {
            const data = kis;
            const maxValue = maxKi;

            const xScale = d3.scaleLinear()
                .domain([0, maxValue])
                .range([0, containerWidth]);

            d3.select(d3Container.current).selectAll("*").remove();

            const svg = d3.select(d3Container.current).append('svg')
                .attr('width', containerWidth)
                .attr('height', containerHeight);

            let cumulativeWidth = 0;

            svg.selectAll('rect')
                .data([data.deployed, data.validating, data.undeployed])
                .enter().append('rect')
                .attr('x', d => {
                    const x = cumulativeWidth;
                    cumulativeWidth += xScale(d);
                    return x;
                })
                .attr('y', 0) // Konstante y-Position
                .attr('width', d => xScale(d))
                .attr('class', (d, i) => {
                    return "unitcompare knowledgeBar dgAll " + ["first", "second", "third"][i]
                });
        }
    }, [containerWidth, containerHeight, lastUpdate, kis, maxKi]);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    const mKi = kis.deployed + kis.undeployed + kis.validating;


    let dTask = (<></>);

    if (selectedDisplay.short == "norm") {
        dTask = (<div>{mKi.toString()}  </div>)
    }

    if (selectedDisplay.short == "full") {
        dTask = (<div className='fullTextLine Kis'>
            <div className='sum'>{mKi}</div>
            <div className='first'>{kis.deployed}</div>
            <div className='second'>{kis.validating}</div>
            <div className='third'>{kis.undeployed}</div>
        </div>)
    }

    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <div className="UnitSectorTitle">
                <div className="UnitSectorTitleText">Wissen <InfoTrigger id="unitcompareKnowledgeItems" /></div>
                <div className="UnitSectorTitleProc">{dTask}</div>
            </div>
            <div className="UnitBarBackgroundDGAll" ref={d3Container}></div>
            <div className='SectorWrapper'>
                {isHovered && (
                    <div className="tooltip knowledgeitems">
                        <div className='tootipLine sum'>
                            <div className='toolTiptext'>Wissen gesamt</div>
                            <div className='toolTipvalue '>{mKi}</div>
                        </div>
                        <div className='tootipLine first'>
                            <div className='toolTiptext'>Deployed</div>
                            <div className='toolTipvalue '>{kis.deployed}</div>
                        </div>
                        <div className='tootipLine second'>
                            <div className='toolTiptext'>Validating</div>
                            <div className='toolTipvalue '>{kis.validating}</div>
                        </div>
                        <div className='tootipLine third'>
                            <div className='toolTiptext'>Developing</div>
                            <div className='toolTipvalue '>{kis.undeployed}</div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    )
}

export default KIBarDGAll;

