import React, { useContext, useEffect, useRef, useState } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UnitButton } from "../naviContainer/UnitSelector";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import UnitBox from "./EinheitenVergleich/UnitBox";
import DGAllUnitBox from "./EinheitenVergleich/DGAllUnitBox";
import VergleichLegende from "./EinheitenVergleich/VergleichLegende";
import fixedPositionWidth from "../helper/fixedPositionWidth";
import cx from 'classnames';

import './EinheitenVergleich/EinheitenVergleich.scss';

export const usedTaskState = ["RESOLVED", "STOPPED", "TERMINATED"];

const EinheitenVergleich: React.FC = () => {
    const parentRef = useRef<HTMLDivElement>(null);
    const width = fixedPositionWidth(parentRef);

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { storage, registerEventListener, unregisterEventListener, selectedType, selectedModule, lastUpdate } = newLocal;
    const [maxKis, setMaxKis] = useState(0);
    const [maxTasks, setMaxTasks] = useState(0);
    const [isOpen, setIsOpen] = useState(true);
    const [rankList, setRankList] = useState<number[]>([]);

    const [sort, setSort] = useState<((a: UnitButton, b: UnitButton) => number) | null>(() => selectedModule.currentSort?.sortFunc || null);
    const forceResize = () => {
        window.setTimeout(() => {
            //Synthetic Event to trigger resize in case the FilterBox is resized
            const event = new Event('resize');
            window.dispatchEvent(event);
        }, 20)
    }

    useEffect(() => {

        const list = registerEventListener("naviResize", () => {
            window.setTimeout(() => {
                //Synthetic Event to trigger resize in case the FilterBox is resized
                const event = new Event('resize');
                window.dispatchEvent(event);
            }, 20)

        })

        const sortListener = registerEventListener("changeSort", (ele) => {
            setSort(() => ele.sortFunc)
        })

        return (() => {
            unregisterEventListener("naviResize", list)
            unregisterEventListener("changeSort", sortListener)
        })

    }, [])

    useEffect(() => {
        if (!storage.has("unitList")) {
            return;
        }
        const rList: { [key: number]: number } = {};
        const allUnits = storage.get("unitList") as UnitButton[];
        let newMaxKi = 0;
        let newMaxTask = 0;

        const allSelected = allUnits[0].isSelected;

        allUnits.forEach((unit,key) => {

            if (key == 0) {
                return;
            }

            if (selectedType.short != "all" && unit.DGunit.type != selectedType.short) {
                return;
            }

            if (!allSelected && !unit.isSelected) {
                return;
            }

            const stat = unit.DGunit.statistic;
            const mKi = stat.kis.deployed + stat.kis.undeployed + stat.kis.validating;
            newMaxKi += mKi;

            const mTask = stat.taskInfo.full + stat.taskInfo.part + stat.taskInfo.not + stat.taskInfo.assist;
            newMaxTask += mTask
            rList[unit.id] = unit.DGunit.statistic.score;

        })
        setRankList(Object.entries(rList).sort((a, b) => b[1] - a[1]).map(entry => parseInt(entry[0])))

        setMaxKis(newMaxKi);
        setMaxTasks(newMaxTask);
        forceResize();
    }, [storage, sort, selectedType, lastUpdate])

    if (!storage.has("unitList")) {
        return (<div>No Units</div>)
    }
    const myUnits = storage.get("unitList") as UnitButton[];
    const showAll = myUnits[0].isSelected;
    let sFunc = (a: UnitButton, b: UnitButton) => { return 0 }
    if (sort) sFunc = sort

    const conf = storage.get("config");

    return (
        <div className="allUnitContainer" ref={parentRef}>
            <div className={cx("DGAllWrapperBox", [{ "minimized": !isOpen }])}>
                <div className={cx("DGAllWrapperFixed", [{ "minimized": !isOpen }])} style={{ width: width }}>
                    <div className="DGAllSeperator " onClick={() => setIsOpen(!isOpen)}>
                        {
                            isOpen && (<div className="minimizeHeaderFlap"><FontAwesomeIcon icon={faAngleUp} /></div>)
                        }
                        {
                            !isOpen && (<div className="minimizeHeaderFlap"><FontAwesomeIcon icon={faAngleDown} /></div>)
                        }

                    </div>
                    {isOpen && (<>
                        <div className="DGMainFixedcontainer">
                            <DGAllUnitBox />
                        </div>
                        <div className="DGLegendFixContainer">
                            <VergleichLegende />
                        </div>
                    </>)}
                    {!isOpen && (
                        <div className="DGMinimizedFixed"> {conf.HIRO_COMPANY_FULL_NAME} | alle Daten</div>
                    )}
                    <div className="DGAllSeperator"></div>
                </div>
            </div>
            <div className="unitWrapper">
                {
                    [...myUnits]
                        .sort(sFunc)
                        .map((ele: UnitButton, position: number) => {

                            if (ele.id == 0) {
                                return;
                            }

                            if (selectedType.short != "all" && ele.DGunit.type != selectedType.short) {
                                return;
                            }
                            const pos = rankList.indexOf(ele.id) + 1;
                            // show only selected or all
                            if (showAll || ele.isSelected) {
                                return (
                                    <UnitBox
                                        key={ele.id}
                                        unit={ele.DGunit}
                                        maxKi={maxKis}
                                        maxTask={maxTasks}
                                        position={pos}
                                    />
                                )
                            }

                            return;

                        })}

            </div>
        </div>
    )
}

export default EinheitenVergleich;