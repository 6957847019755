import { sortElement } from "../../globalProvider";
import { TimeButton } from "./TimespanSelector";

interface SortButtonElementProps {
    sort: sortElement;
    isSelected: boolean;
}

const SortButtonElement: React.FC<SortButtonElementProps> = ({ sort, isSelected }) => {
    return (
        <option className="timeOption" value={sort.id} >
            {sort.display}
        </option>
    );
}

export default SortButtonElement;
