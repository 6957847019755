import { useContext, useEffect, useState } from 'react';
import { faArrowsAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Draggable from 'react-draggable';
import ReactDOM from 'react-dom';
import { GlobalContext, GlobalContextProps } from '../../globalProvider';
import infoTexts from './infoTexts';

import './infoTrigger.scss';
const InfoWindow: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [content,setContent] = useState(<div>No key</div>)
    const [container] = useState(() => document.createElement('div'));

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { registerEventListener, unregisterEventListener, sendMessage } = newLocal;

    const handleClose = () => {
        sendMessage("showInfoWindow","none")
    }

    const handleOpen = (id: string) => {
        if (id == "none") {
            setIsOpen(false);
            return;
        }
        if (infoTexts[id]) {
            setContent(infoTexts[id])
        } else {
            setContent(<div>Key {id} not found!</div>)
        }
        setIsOpen(true);
    }

    useEffect(() => {
        const el = registerEventListener("showInfoWindow", handleOpen);
        const us = registerEventListener("switchDisplayModule",handleClose)

        return (() => {
            unregisterEventListener("showInfoWindow", el)
            unregisterEventListener("switchDisplayModule", us)
        })
    }, [])

    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, [container]);

    

    const popupContent = isOpen && (
        <Draggable handle=".infoWindowHeader" bounds="body">
            <div className="infoWindow">
                <div className="infoWindowHeader">
                    <div className="dragIcon"><FontAwesomeIcon icon={faArrowsAlt} /></div>
                    <div className="closeHandler" onClick={handleClose}><FontAwesomeIcon icon={faTimes} /></div>
                </div>
                <div className="infoWindowContent">{content}</div>
            </div>
        </Draggable>
    );

    return ReactDOM.createPortal(popupContent, container);
}

export default InfoWindow;