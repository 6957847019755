import React, { useContext, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { GlobalContext, GlobalContextProps } from '../../../globalProvider';
import svgShadowFilter from '../../helper/svgShadowFilter';
import InfoTrigger from '../../infoWindow/InfoTrigger';


type AutomatisierungsBarProps = {
    automationRates: number[];
    autoAutomationRate: number;
}

const AutomatisierungsBarDGAll: React.FC<AutomatisierungsBarProps> = ({ automationRates, autoAutomationRate }) => {

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { selectedDisplay, lastUpdate } = newLocal;

    const d3Container = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [myRate, setMyRate] = useState(0);
    const [myFullRate, setFullRate] = useState(0);

    useEffect(() => {
        let isMounted = true;
        if (d3Container.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    window.requestAnimationFrame(() => {
                        if (isMounted) {
                            setContainerWidth(entry.contentRect.width);
                            setContainerHeight(entry.contentRect.height);
                        }
                    });

                }
            });

            resizeObserver.observe(d3Container.current);
            return () => {
                isMounted = false;
                resizeObserver.disconnect();
            };
        }
    }, [d3Container]);

    useEffect(() => {
        if (containerWidth > 0 && containerHeight > 0) {
            const mean = automationRates.reduce((sum, val) => sum + val, 0) / automationRates.length;
            if (!mean) return;

            setMyRate(Math.floor(mean * 100) / 100);
            const sd = Math.sqrt(automationRates.map(val => (val - mean) ** 2).reduce((sum, val) => sum + val, 0) / automationRates.length);
            const min = Math.min(...automationRates);
            const max = Math.max(...automationRates);

            d3.select(d3Container.current).selectAll("*").remove();

            const svg = d3.select(d3Container.current).append('svg')
                .attr('width', containerWidth)
                .attr('height', containerHeight);

            let x = d3.scaleLinear()
                .domain([0, 100])
                .range([0, containerWidth]);

            svgShadowFilter(svg);

            //Min bis Max
            svg
                .append('rect')
                .attr('filter', 'url(#shadow)')
                .attr('x', x(min))
                .attr('y', 0)
                .attr('width', x(max) - x(min))
                .attr('class', 'unitcompare automation DGAll shadow')

            //STD-Abweichung
            svg
                .append('rect')
                .attr('x', x(Math.max(mean - sd, 0)))
                .attr('y', 0)
                .attr('width', x(Math.min(mean + sd, 100)) - x(Math.max(mean - sd, 0)))
                .attr('class', 'unitcompare automation DGAll standard')

            svg
                .append('rect')
                .attr('filter', 'url(#shadow)')
                .attr('x', x(Math.max(mean - sd, 0)))
                .attr('y', 0)
                .attr('width', x(Math.min(mean + sd, 100)) - x(Math.max(mean - sd, 0)))
                .attr('class', 'unitcompare automation DGAll standardShadow')

            svg.append('line')
                .attr('x1', x(mean))
                .attr('x2', x(mean))
                .attr('y1', 3)
                .attr('y2', 27)
                .attr('opacity', 0.1)
                .attr('class', 'unitcompare automation DGAll meanLine')


            svg.append('line')
                .attr('x1', x(autoAutomationRate))
                .attr('x2', x(autoAutomationRate))
                .attr('y1', 3)
                .attr('y2', 27)
                .attr('class', 'unitcompare automation DGAll meanLine')
        }

    }, [containerWidth, containerHeight, lastUpdate]);

    const handleMouseOver = () => {
        setIsHovered(true);
    };


    const handleMouseOut = () => {
        setIsHovered(false);
    };

    let dTask = "";

    if (selectedDisplay.short == "norm") {
        dTask = (autoAutomationRate).toString() + "%";
    }

    if (selectedDisplay.short == "full") {
        dTask = (autoAutomationRate).toString() + "%";
    }


    return (
        <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <div className="UnitSectorTitle">
                <div className="UnitSectorTitleText">Automatisierung <InfoTrigger id="unitcompareAutomation" /></div>
                <div className="UnitSectorTitleProc">{dTask}</div>
            </div>
            <div className="UnitBarBackgroundDGAll" ref={d3Container}></div>
            <div className='SectorWrapper'>
                {isHovered && (
                    <div className="tooltip">
                        <div className='tootipLine sum'>
                            <div className='toolTiptext'>Automatisierung</div>
                            <div className='toolTipvalue '>{autoAutomationRate}%</div>
                        </div>
                    </div>)
                }
            </div>
        </div>
    )
}

export default AutomatisierungsBarDGAll;

