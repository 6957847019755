import React, { useContext, useEffect, useRef, useState } from "react";
import DGAllHumanBox from "./Menschen/DGAllHumanBox";
import HumanLegende from "./Menschen/HumanLegende";
import fixedPositionWidth from "../helper/fixedPositionWidth";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";
import { UnitButton } from "../naviContainer/UnitSelector";
import UnitBox from "./Menschen/UnitBox";
import cx from 'classnames';
import './Menschen/Human.scss';
import { DGunit } from "../../dataStruct/DGUnit";

const Menschen: React.FC = () => {
    const parentRef = useRef<HTMLDivElement>(null);
    const width = fixedPositionWidth(parentRef);

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { storage, meta, registerEventListener, unregisterEventListener, selectedModule, selectedType,lastUpdate } = newLocal;

    const [isOpen, setIsOpen] = useState(true);
    const [allDGUnit, setAllDgUnit] = useState<DGunit>();
    const [maxUser, setMaxUser] = useState(0);
    const [maxKis, setMaxKis] = useState(0);
    const [maxFactor, setMaxFactor] = useState(0);
    const [sort, setSort] = useState<((a: UnitButton, b: UnitButton) => number) | null>(() => selectedModule.currentSort?.sortFunc || null);

    const forceResize = () => {
        window.setTimeout(() => {
            //Synthetic Event to trigger resize in case the FilterBox is resized
            const event = new Event('resize');
            window.dispatchEvent(event);
        }, 20)
    }

    useEffect(() => {
        if (!storage.has("unitList")) {
            return;
        }
        const allUnits = storage.get("unitList") as UnitButton[];
        const myUnit = JSON.parse(JSON.stringify(allUnits[0])) as UnitButton; // Clone the Helper Element  
        let mU = 0;

        const allSelected = allUnits[0].isSelected;

        allUnits.forEach((unit, key) => {
            if (key == 0) {
                return;
            }

            if (selectedType.short != "all" && unit.DGunit.type != selectedType.short) {
                return;
            }

            if (!allSelected && !unit.isSelected) {
                return;
            }

            const stat = unit.DGunit.statistic.user;

            myUnit.DGunit.statistic.user.ammount.sme += stat.ammount.sme;
            myUnit.DGunit.statistic.user.ammount.ke += stat.ammount.ke;
            myUnit.DGunit.statistic.user.ammount.both += stat.ammount.both;
            myUnit.DGunit.statistic.user.ammount.all += stat.ammount.all;

            // Handle userHashes
            myUnit.DGunit.statistic.user.hash.sme = [...myUnit.DGunit.statistic.user.hash.sme,...stat.hash.sme]
            myUnit.DGunit.statistic.user.hash.ke = [...myUnit.DGunit.statistic.user.hash.ke,...stat.hash.ke]
            myUnit.DGunit.statistic.user.hash.both = [...myUnit.DGunit.statistic.user.hash.both,...stat.hash.both]
            myUnit.DGunit.statistic.user.hash.all = [...myUnit.DGunit.statistic.user.hash.all,...stat.hash.all]
    
            mU = Math.max(mU, stat.ammount.all)
            
        })

        myUnit.DGunit.statistic.user.ammount.sme = myUnit.DGunit.statistic.user.hash.sme.length;
        myUnit.DGunit.statistic.user.ammount.ke = myUnit.DGunit.statistic.user.hash.ke.length;
        myUnit.DGunit.statistic.user.ammount.both = myUnit.DGunit.statistic.user.hash.both.length;
        myUnit.DGunit.statistic.user.ammount.all = myUnit.DGunit.statistic.user.hash.all.length;

        myUnit.DGunit.statistic.user.transfer.max = meta.transfer.max;
        myUnit.DGunit.statistic.user.transfer.min = meta.transfer.min;
        myUnit.DGunit.statistic.user.transfer.mean = meta.transfer.mean;
        myUnit.DGunit.statistic.user.transfer.std = meta.transfer.std;

        myUnit.DGunit.statistic.user.kis.max = meta.kis.max;
        myUnit.DGunit.statistic.user.kis.min = meta.kis.min;
        myUnit.DGunit.statistic.user.kis.mean = meta.kis.mean;
        myUnit.DGunit.statistic.user.kis.std = meta.kis.std;

        myUnit.DGunit.statistic.user.multifactor.max = meta.multifactor.max;
        myUnit.DGunit.statistic.user.multifactor.min = meta.multifactor.min;
        myUnit.DGunit.statistic.user.multifactor.mean = meta.multifactor.mean;
        myUnit.DGunit.statistic.user.multifactor.std = meta.multifactor.std;

        setAllDgUnit(myUnit.DGunit);
        setMaxUser(mU);
        setMaxKis(meta.kis.max);
        setMaxFactor(meta.multifactor.max);
        forceResize();

    }, [storage, sort, selectedType, lastUpdate])

    useEffect(() => {
        const list = registerEventListener("naviResize", forceResize);
        const sortListener = registerEventListener("changeSort", (ele) => {
            setSort(() => ele.sortFunc)
        });

        return (() => {
            unregisterEventListener("naviResize", list)
            unregisterEventListener("changeSort", sortListener)
        })

    }, [])

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    if (!storage.has("unitList") || !allDGUnit) {
        return (<div>No Units</div>)
    }

    const myUnits = storage.get("unitList") as UnitButton[];
    const showAll = myUnits[0].isSelected;
    const conf = storage.get("config");

    let sFunc = (a: UnitButton, b: UnitButton) => { return 0 }
    if (sort) sFunc = sort

    return (
        <div className="allUnitContainer" ref={parentRef}>
            <div className={cx("DGAllWrapperBox", [{ "minimized": !isOpen }])}>
                <div className={cx("DGAllWrapperFixed", [{ "minimized": !isOpen }])} style={{ width: width }}>
                    <div className="DGAllSeperator " onClick={handleClick}>
                        {
                            isOpen && (<div className="minimizeHeaderFlap"><FontAwesomeIcon icon={faAngleUp} /></div>)
                        }
                        {
                            !isOpen && (<div className="minimizeHeaderFlap"><FontAwesomeIcon icon={faAngleDown} /></div>)
                        }

                    </div>
                    {isOpen && (<>
                        <div className="DGMainFixedcontainer">
                            <DGAllHumanBox
                                dgUnit={allDGUnit}
                            />
                        </div>
                        <div className="DGLegendFixContainer">
                            <HumanLegende />
                        </div>
                    </>)}
                    {!isOpen && (
                        <div className="DGMinimizedFixed"> {conf.HIRO_COMPANY_FULL_NAME} | alle Daten</div>
                    )}
                    <div className="DGAllSeperator"></div>
                </div>
            </div>
            <div className="unitWrapper">
                {
                    [...myUnits]
                        .sort(sFunc)
                        .map((ele: UnitButton, position: number) => {
                            if (ele.id == 0) {
                                return;
                            }
                            if (selectedType.short != "all" && ele.DGunit.type != selectedType.short) {
                                return;
                            }

                            // show only selected or all
                            if (showAll || ele.isSelected) {
                                return (
                                    <UnitBox
                                        key={ele.id}
                                        unit={ele.DGunit}
                                        maxUser={maxUser}
                                        maxKis={maxKis}
                                        maxFactor={maxFactor}
                                    />
                                )
                            }

                            return;

                        })}
            </div>
        </div>
    )
}
export default Menschen;