import './index.css';
import App from './App';
import { initSdk, unauthorized } from '@hiro-ui/sdk';
import Client, { Token } from '@hiro-graph/client';
import { InitSdkState } from './baseTypes';
import ReactDOM from 'react-dom/client';
import axios from 'axios';

const getRootElement = (): HTMLElement =>  {
  let rootElement = document.getElementById('root');
  if (!rootElement) {
    // Erstelle ein Platzhalterelement, wenn es nicht existiert
    rootElement = document.createElement('div');
    rootElement.id = 'root';
    document.body.appendChild(rootElement);
  }
  return rootElement;
}


const root = ReactDOM.createRoot(getRootElement());


const isDev = process.env.NODE_ENV === 'development';
const configRoot = process.env.HIRO_STATISTIC_CONFIG || '';

axios.get(configRoot + "/config.json")
  .then(response => {
    const myconf = response.data;
    const env = {
      clientId: myconf.HIRO_CLIENT_ID,
      graphUrl: myconf.HIRO_GRAPH_URL,
      authUrl: myconf.HIRO_AUTH_URL,
      redirectUrl: myconf.HIRO_REDIRECT_URL,
      role: myconf.HIRO_USER_ROLE,
      scopeId: myconf.HIRO_SCOPE_ID,
    };

    const eData = {
      HIRO_STATISTIC_ENDPOINT: myconf.HIRO_STATISTIC_ENDPOINT,
      HIRO_STATISTIC_MOCKDATA: myconf.HIRO_STATISTIC_MOCKDATA,
      HIRO_COMPANY_FULL_NAME: myconf.HIRO_COMPANY_FULL_NAME || "Unknown Name",
      HIRO_REQUEST_EMAIL: myconf.HIRO_REQUEST_EMAIL || "unknown@mail.def"
    }

    if (myconf.HIRO_STATISTIC_NOGRAPH == "true") {
      const gr = {} as Client;
      root.render((
        <App
          graph={gr}
          conf={eData}
        />
      ));
    } else {

      initSdk<InitSdkState>({
        clientId: env.clientId,
        graphUrl: env.graphUrl,
        authUrl: env.authUrl,
        redirectUrl: env.redirectUrl,
        role: env.role,
        scopeId: env.scopeId,
      }).then(
        ({
          config: {
            token,
            graphUrl,
          },
          ready,
          state,
        }) => {
          if (isDev) {
            token = process.env.HIRO_FORCE_TOKEN || token;
          }
          const graphClient = new Client({
            endpoint: graphUrl,
            token: new Token({
              onInvalidate: () => {
                unauthorized();
                return Promise.resolve();
              },
              getToken: () => Promise.resolve(token),
            }),
          });

          root.render((
            <App
              graph={graphClient}
              conf={eData}
            />
          ));

          ready();
        },
      ).catch(error => {
        console.log(error);
      })
    }
  })
  .catch(error => {
    console.log(error)
    root.render((<div>Failed to load config.json</div>))
  });
