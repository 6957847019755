
import TicketBar from "./TicketBar";
import KIBar from "./KIBar";
import AutomatisierungsBar from "./AutomatisierungsBar";
import WiederverwendungsBar from "./WiederverwendungsBar";
import InfoTrigger from "../../infoWindow/InfoTrigger";
import cx from 'classnames';
import { DGunit } from "../../../dataStruct/DGUnit";

type UnitBoxProps = {
    unit: DGunit,
    maxKi: number,
    maxTask: number,
    position: number
}

const trophies = [
    "First",
    "Second",
    "Third"
]

const getTrophy = (rank: number) => {
    if (rank > 3) {
        return (<></>)
    }

    const path = "/images/" + trophies[rank-1] + ".svg";
    return (<div className="UnitBoxTrophy"><img src={path} /></div>)

}


const UnitBox: React.FC<UnitBoxProps> = ({ unit,position,maxKi,maxTask }) => {
    const infoClazz = cx("infoContainerScore","element_"+position)
    return (
        <div className="UnitBox">
            <div className="UnitBoxTitle">{unit.name}</div>
            <div className="UnitBoxScore">{Math.floor(unit.statistic.score * 100) / 100} <div className={infoClazz}><InfoTrigger id="unitcompareInfoContainerScore" /></div></div>
            {getTrophy(position)}
            <div className="UnitBoxContent">
                <div className="UnitSector">
                    <TicketBar
                        tickets={unit.statistic.taskInfo}
                        maxTicket={maxTask}
                    />
                </div>
                
                <div className="UnitSector">
                    <KIBar
                        kis={unit.statistic.kis}
                        maxKi={maxKi}
                    />
                </div>
                <div className="UnitSector">
                    <AutomatisierungsBar
                        tickets={unit.statistic.taskInfo}
                    />
                </div>
                <div className="UnitSector">
                    <WiederverwendungsBar
                       rate={unit.statistic.reuse}
                    />
                </div>
            </div>
            
        </div>
    )
}

export default UnitBox;