import './App.css';
import React, { useState } from 'react';
import AppMainContainer from './components/appContainer'

import { GlobalContextProps, GlobalProvider } from './globalProvider';
import Client from '@hiro-graph/client';
import CriticalError from './CriticalError';
import Loading from './Loading';

interface appProps {
  graph: Client,
  conf: any
}

const App: React.FC<appProps> = ({ graph, conf }) => {
  const [blockingErrorNumber, setblockingErrorNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  let ret = <Loading />
  

  if (!isLoading) {
    if (blockingErrorNumber !== 0) {
      ret = <CriticalError
        errorCode={blockingErrorNumber}
      />
    }
    else {
      ret = <AppMainContainer />
    }
  }
  
  return (
    <GlobalProvider
      graph={graph}
      errorHandler={setblockingErrorNumber}
      initHandler={setIsLoading}
      config={conf}
    > 
      {ret}
    </GlobalProvider>
  );

}
export default App;