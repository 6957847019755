
export type taskType = {
    [key: string]: number
}

export type taskInfoType =
    {
        full: number;
        not: number;
        assist: number;
        part: number;
    }



export type DGunit = {
    name: string;
    short: string;
    type: string;
    hiroId: string;
    statistic: {
        kis:
        {
            deployed: number,
            undeployed: number,
            validating: number
        },
        user: {
            ammount: {
                sme: number;
                ke: number;
                both: number;
                all: number;
            },
            hash: {
                sme: string[];
                ke: string[];
                both: string[];
                all: string[];
            }
            transfer: {
                min: number;
                max: number;
                mean: number;
                std: number;
            },
            kis: {
                min: number;
                max: number;
                mean: number;
                std: number;
            },
            multifactor: {
                min: number;
                max: number;
                mean: number;
                std: number;
            }
        }
        tasks: taskType,
        taskInfo: taskInfoType,
        score: number,
        rate: number,
        reuse: number,
        allSME: number,
        smeSteps: number,
        convertedSteps: number
    }
}

export const defaultUnit: DGunit = {
    name: "Alle",
    short: "Alle",
    hiroId: "",
    type: "all",
    statistic: {
        score: 10000,
        allSME: 0,
        kis: { deployed: 0, undeployed: 0, validating: 0 },
        tasks: {},
        user: {
            ammount: {
                sme: 0,
                ke: 0,
                both: 0,
                all: 0
            },
            hash: {
                all: [],
                both: [],
                ke: [],
                sme: []
            },
            transfer: {
                max: 0,
                min: 0,
                mean: 0,
                std: 0
            },
            kis: {
                max: 0,
                min: 0,
                mean: 0,
                std: 0
            },
            multifactor: {
                max: 0,
                min: 0,
                mean: 0,
                std: 0
            }
        },
        taskInfo: {
            full: 0,
            assist: 0,
            part: 0,
            not: 0
        },
        rate: 0,
        reuse: 0,
        smeSteps: 0,
        convertedSteps: 0
    }
};