import { FC } from "react";
import EinheitenVergleich from "../components/modules/EinheitenVergleich";
import Historie from "../components/modules/Historie";
import KnowledgeTransfer from "../components/modules/KnowledgeTransfer";
import Menschen from "../components/modules/Menschen";
import { UnitButton } from "../components/naviContainer/UnitSelector";
import { sortElement } from "../globalProvider";
import { ReactComponent as SVGUnits } from '../components/modules/SVG/Units.svg'
import { ReactComponent as SVGKnowledgeTransfer } from '../components/modules/SVG/KnowledgeTransfer.svg'
import { ReactComponent as SVGHuman } from '../components/modules/SVG/Human.svg'
import { ReactComponent as SVGHistory } from '../components/modules/SVG/History.svg'

export type hookComponent = {
    id: string;
    desc: string;
    icon: FC<React.SVGProps<SVGSVGElement>>;
    hook: React.FC;
    currentSort: sortElement | null;
    sortList: sortElement[]
}


type SvgWrapperProps = {
    SvgComponent: FC<React.SVGProps<SVGSVGElement>>;
    className?: string;
};



export const SvgWrapper: FC<SvgWrapperProps> = ({ SvgComponent, className }) => {
    return <SvgComponent className={className} />;
};


export const knownModules: hookComponent[] = [
    {
        id: "Compare",
        desc: "Unit Vergleich",
        icon: SVGUnits,
        hook: EinheitenVergleich,
        currentSort: null,
        sortList: [{
            id: "0",
            display: "Score",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;
                if (a.DGunit.statistic.score < b.DGunit.statistic.score) return 1;
                if (a.DGunit.statistic.score > b.DGunit.statistic.score) return -1;
                return 0;
            },
        }, {
            id: "1",
            display: "Name",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;
                if (a.DGunit.name < b.DGunit.name) return 1;
                if (a.DGunit.name < b.DGunit.name) return -1;
                return 0;
            },
        }]
    }, {
        id: "KnowlegeTransfer",
        desc: "Knowledge Transfer",
        icon: SVGKnowledgeTransfer,
        hook: KnowledgeTransfer,
        currentSort: null,
        sortList: [{
            id: "0",
            display: "SME Schritte",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const smea = a.DGunit.statistic.smeSteps;
                const smeb = b.DGunit.statistic.smeSteps;

                if (smea > smeb) return -1;
                if (smea < smeb) return 1;
                return 0;
            },
        }, {
            id: "1",
            display: "Ticket",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const smea = a.DGunit.statistic.taskInfo.full + a.DGunit.statistic.taskInfo.part + a.DGunit.statistic.taskInfo.not + a.DGunit.statistic.taskInfo.assist;
                const smeb = b.DGunit.statistic.taskInfo.full + b.DGunit.statistic.taskInfo.part + b.DGunit.statistic.taskInfo.not + b.DGunit.statistic.taskInfo.assist;

                if (smea > smeb) return -1;
                if (smea < smeb) return 1;
                return 0;
            },
        }, {
            id: "2",
            display: "Teil-/Vollautomatisiert",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const smea = a.DGunit.statistic.taskInfo.full + a.DGunit.statistic.taskInfo.part + a.DGunit.statistic.taskInfo.assist;
                const smeb = b.DGunit.statistic.taskInfo.full + b.DGunit.statistic.taskInfo.part + b.DGunit.statistic.taskInfo.assist;

                if (smea > smeb) return 1;
                if (smea < smeb) return -1;
                return 0;
            },
        }, {
            id: "3",
            display: "SME Sessions",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const smea = a.DGunit.statistic.allSME;
                const smeb = b.DGunit.statistic.allSME;

                if (smea > smeb) return -1;
                if (smea < smeb) return 1;
                return 0;
            },
        }, {
            id: "4",
            display: "Konvertierte Steps",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const smea = a.DGunit.statistic.convertedSteps;
                const smeb = b.DGunit.statistic.convertedSteps;

                if (smea > smeb) return -1;
                if (smea < smeb) return 1;
                return 0;
            },
        }, {
            id: "5",
            display: "Kis live",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const smea = a.DGunit.statistic.kis.deployed;
                const smeb = b.DGunit.statistic.kis.deployed;

                if (smea > smeb) return -1;
                if (smea < smeb) return 1;
                return 0;
            },
        }, {
            id: "6",
            display: "Name",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;
                if (a.DGunit.name < b.DGunit.name) return 1;
                if (a.DGunit.name < b.DGunit.name) return -1;
                return 0;
            },
        }]
    }, {
        id: "Humans",
        desc: "Nutzer",
        icon: SVGHuman,
        hook: Menschen,
        currentSort: null,
        sortList: [{
            id: "0",
            display: "KIs",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const kiA = a.DGunit.statistic.user.kis.mean;
                const kiB = b.DGunit.statistic.user.kis.mean;

                if (kiA > kiB) return -1;
                if (kiA < kiB) return 1;
                return 0;
            },
        }, {
            id: "1",
            display: "Nutzer",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const kiA = a.DGunit.statistic.user.ammount.all;
                const kiB = b.DGunit.statistic.user.ammount.all;

                if (kiA > kiB) return -1;
                if (kiA < kiB) return 1;
                return 0;
            },
        }, {
            id: "2",
            display: "Wissenstransfer",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const kiA = a.DGunit.statistic.user.transfer.mean;
                const kiB = b.DGunit.statistic.user.transfer.mean;

                if (kiA > kiB) return -1;
                if (kiA < kiB) return 1;
                return 0;
            },
        }, {
            id: "3",
            display: "Multiplikationsfaktor",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const kiA = a.DGunit.statistic.user.multifactor;
                const kiB = b.DGunit.statistic.user.multifactor;

                if (kiA > kiB) return -1;
                if (kiA < kiB) return 1;
                return 0;
            },
        }, {
            id: "4",
            display: "Wiederverwendungsfaktor",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const kiA = a.DGunit.statistic.user.kis;
                const kiB = b.DGunit.statistic.user.kis;

                if (kiA > kiB) return -1;
                if (kiA < kiB) return 1;
                return 0;
            },
        }, {
            id: "5",
            display: "Name",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;
                if (a.DGunit.name < b.DGunit.name) return 1;
                if (a.DGunit.name < b.DGunit.name) return -1;
                return 0;
            },
        }]
    },
    {
        id: "History",
        desc: "Historische Betrachtung",
        icon: SVGHistory,
        hook: Historie,
        currentSort: null,
        sortList: [{
            id: "0",
            display: "Volle Automatisierung",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const autoa = a.DGunit.statistic.taskInfo.full;
                const autob = b.DGunit.statistic.taskInfo.full;

                if (autoa > autob) return -1;
                if (autoa < autob) return 1;
                return 0;
            },
        }, {
            id: "1",
            display: "Name",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;
                if (a.DGunit.name < b.DGunit.name) return 1;
                if (a.DGunit.name < b.DGunit.name) return -1;
                return 0;
            },
        }]

    }
    /*
    , {
        id: "Automation",
        desc: "Automationsergebnis",
        icon: SVGAutomation,
        hook: Automationsergebnis,
        currentSort: null,
        sortList: [{
            id: "0",
            display: "Volle Automatisierung",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;

                const autoa = a.DGunit.statistic.taskInfo.full;
                const autob = b.DGunit.statistic.taskInfo.full;

                if (autoa > autob) return -1;
                if (autoa < autob) return 1;
                return 0;
            },
        }, {
            id: "1",
            display: "Name",
            sortFunc: (a: UnitButton, b: UnitButton) => {
                if (a.id == 0) return -1;
                if (b.id == 0) return 1;
                if (a.DGunit.name < b.DGunit.name) return 1;
                if (a.DGunit.name < b.DGunit.name) return -1;
                return 0;
            },
        }]

    }
    */
]