import { ReactComponent as SVGHistory } from '../SVG/Line.svg'
import { ReactComponent as SVGHistory2 } from '../SVG/Line2.svg'

const HistorienLegende: React.FC = ({ }) => {

    return (
        <>
            <div className="legendBox">
                <div className="legendTitle">Tickets:</div>
                <div className="legendLines">
                    <div className="legendColorBox first"></div>
                    <div className="legendText">Vollautomatisiert</div>
                </div>
                <div className="legendLines">
                    <div className="legendColorBox second"></div>
                    <div className="legendText">Teilautomatisiert</div>
                </div>
                <div className="legendLines">
                    <div className="legendColorBox third"></div>
                    <div className="legendText">Nicht automatisiert</div>
                </div>
                <div className="legendLines">
                    <div className="legendColorBox"><SVGHistory /></div>
                    <div className="legendText">Automatisierungsgrad</div>
                </div>
            </div>
            <div className="legendBox">
                <div className="legendTitle">Wissen:</div>
                <div className="legendLines">
                    <div className="legendColorBox fourth"></div>
                    <div className="legendText">Produktion</div>
                </div>
                <div className="legendLines">
                    <div className="legendColorBox fifth"></div>
                    <div className="legendText">Validierung</div>
                </div>
                <div className="legendLines">
                    <div className="legendColorBox sixth"></div>
                    <div className="legendText">Entwicklung</div>
                </div>
                <div className="legendLines">
                    <div className="legendColorBox"><SVGHistory2 /></div>
                    <div className="legendText">Wiederverwendung</div>
                </div>
            </div>
        </>
    )
}

export default HistorienLegende;
