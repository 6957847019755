import { useContext, useEffect, useState } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";
import { knownModules, SvgWrapper } from "../../dataStruct/Modules";
import cx from 'classnames';

const ModuleSelector: React.FC = () => {
    const [isResponsiveOpen, setIsResponsiveOpen] = useState(false);
    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { registerEventListener, unregisterEventListener, selectedModule, sendMessage } = newLocal;

    const showResponsive = (b: boolean) => {
        setIsResponsiveOpen(b);
    }

    useEffect(() => {
        const el = registerEventListener('openNavi', showResponsive);
        
        return () => {
            unregisterEventListener("openNavi", el)
        };
    }, []);


    return (
        <div className={cx("moduleSelectorBox", { 'moduleMightBeOpen': isResponsiveOpen, 'moduleMightBeClosed': !isResponsiveOpen } )}>
            {knownModules.map(mod => {
                if (mod == selectedModule) {
                    return (<div key={mod.id} className='moduleSelector selectedModule'>
                        <SvgWrapper SvgComponent={mod.icon} className="selectedIcon" />
                        <div className="moduleDescriptor">{mod.desc}</div>
                    </div>)
                }
                return (<div key={mod.id} className='moduleSelector selectableModule' onClick={() => {
                    sendMessage("switchDisplayModule", mod);
                }}>
                    <SvgWrapper SvgComponent={mod.icon} className="selectableIcon" />
                    <div className="moduleDescriptor">{mod.desc}</div>
                </div>)
            })}
        </div>
    )
    
}

export default ModuleSelector;