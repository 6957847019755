import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { GlobalContext, GlobalContextProps } from "../../globalProvider";

export const historyDisplay: string[] = ["tasks", "kis"];

const HistorySwitch: React.FC = () => {
    
    const [isToggled, setIsToggled] = useState(false);
    

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { sendMessage } = newLocal;

    const handleClick = (evt: ChangeEvent<HTMLSelectElement>) => {
        const newSel: number = evt.target.value as unknown as number;

    }

    useEffect(() => {
        sendMessage("historySelected",historyDisplay[isToggled?1:0])
    },[isToggled])

   
    const toggleSwitch = () => {
        setIsToggled(!isToggled)
    };





    return (
        <div className="timespanContainer">
            <div>Datenbasis:</div>
            <div>
                <label className="switch">
                    <input type="checkbox" checked={isToggled} onChange={toggleSwitch} />
                    <span className="slider">
                        <span className="handle"></span>
                        <span className="handle-text">{isToggled ? 'Kis' : 'Tickets'}</span> {/* Text direkt im Handle */}
                    </span>
                    <div className="background">
                        <div className="bg-left">{isToggled ? 'Tickets' : ''}</div>
                        <div className="bg-right">{isToggled ? '' : 'Kis'}</div>
                    </div>
                </label>
            </div>
        </div>
    );
}

export default HistorySwitch;
