import React, { ChangeEvent, useContext, useState } from "react";
import { GlobalContext, GlobalContextProps } from "../../globalProvider";
import SortButtonElement from "./SortButtonElement";



const SortSelector: React.FC = () => {
    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { sendMessage, selectedModule, storage } = newLocal;

    const [sortV, setSortV] = useState("-1");
   
    const handleClick = (evt: ChangeEvent<HTMLSelectElement>) => {
        const newSel: string = evt.target.value as unknown as string;
        selectedModule.currentSort = selectedModule.sortList.filter(ele => { return ele.id == newSel })[0]
        setSortV(selectedModule.currentSort.id)
        sendMessage("changeSort", selectedModule.currentSort)
    }

    if (selectedModule.currentSort == undefined) {
        selectedModule.currentSort = selectedModule.sortList[0];
    }

    return (
        <div className="timespanContainer">
            <div>Sortierung:</div>
            <div>
                <select className="timeSelector" onChange={handleClick} value={sortV}> 
                    {selectedModule.sortList.map(ele => {
                        return <SortButtonElement
                            sort={ele}
                            key={ele.id}
                            isSelected={selectedModule.currentSort?.id == ele.id }
                        />
                    })}
                    
                </select>
            </div>
        </div>
    );
}

export default SortSelector;
