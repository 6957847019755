import { DGunit } from "../../../dataStruct/DGUnit";
import AutomationRateContainer from "./AutomationRateContainer";
import DGAllTicketBar from "./DGAllTicketBar";

type DGAllTickerMasterBoxProps = {
    dgUnit: DGunit
    maxTickets: number;
}

const DGAllTickerMasterBox: React.FC<DGAllTickerMasterBoxProps> = ({ dgUnit,maxTickets }) => {

    const t = dgUnit.statistic.taskInfo;
    
    return (
        <div className="DGAllTicketMasterBox">
            <DGAllTicketBar
                maxTicket={maxTickets}
                tickets={{
                    full: t.full,
                    assist: t.assist,
                    part: t.part,
                    not: t.not
                }}
            />
            <AutomationRateContainer
                dgUnit={dgUnit}
            />
        </div>
    )

}

export default DGAllTickerMasterBox;