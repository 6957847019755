import { useState, useEffect, RefObject } from 'react';

const fixedPositionWidth = (ref: RefObject<HTMLElement>): number => {
    const [width, setWidth] = useState<number>(0);

    useEffect(() => {
        const updateWidth = () => {
            if (ref.current) {
                setWidth(ref.current.offsetWidth -10 );
            }
        };

        window.addEventListener('resize', updateWidth);
        updateWidth(); // Initialer Aufruf, um die Breite zu setzen

        return () => window.removeEventListener('resize', updateWidth);
    }, [ref]);

    return width;
};

export default fixedPositionWidth;
