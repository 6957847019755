import { useContext, useEffect, useImperativeHandle, forwardRef, useRef, useState } from "react"
import { HistGroupType, HistSequenceBlock, HistType } from "../../../dataStruct/HistData"
import { GlobalContextProps, GlobalContext } from "../../../globalProvider"
import { UnitButton } from "../../naviContainer/UnitSelector"
import { allDisTypes, HistoryFormat } from "./DGAllHistoryBox"
import * as d3 from 'd3';

type HistoryUnitBoxProps = {
    unit: UnitButton,
    histData: HistSequenceBlock,
    startTick: string
}

export interface HistoryUnitBoxHandle {
    getInnerData: () => any;
}

const HistoryUnitBox = forwardRef<HistoryUnitBoxHandle, HistoryUnitBoxProps>(({ unit, histData, startTick }, ref) => {

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { selectedSequence, selectedHistory } = newLocal;

    const d3Container = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [maxTask, setMaxTask] = useState(0);
    const [maxKi, setMaxKis] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [innerData, setInnerData] = useState<HistoryFormat[]>([]);

    useImperativeHandle(ref, () => ({
        getInnerData: () => {
            return { data: histData, name: unit.name, isAll:false }
        },
    }));

    useEffect(() => {
        let isMounted = true;
        if (d3Container.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    window.requestAnimationFrame(() => {
                        if (isMounted) {
                            setContainerWidth(entry.contentRect.width);
                            setContainerHeight(entry.contentRect.height);
                        }
                    });

                }
            });

            resizeObserver.observe(d3Container.current);
            return () => {
                isMounted = false;
                resizeObserver.disconnect();
            };
        }
    }, [d3Container]);

    
    useEffect(() => {
        //Prepare Data

        if (!histData) return;
        const formatDate = (date: Date, format: string) => {
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const week = Math.floor((date.getTime() - new Date(year, 0, 1).getTime()) / (7 * 24 * 60 * 60 * 1000)) + 1; // Berechnet die Wochennummer

            switch (format) {
                case "YYYY":
                    return `${year}`;
                case "YYYY-MM":
                    return `${year}-${month.toString().padStart(2, '0')}`;
                case "YYYY-MM-DD":
                    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                case "YYYY-W":
                    return `${year}-W${week.toString().padStart(2, '0')}`;
                default:
                    return date.toISOString().substring(0, 10);
            }
        }

        const getStartForWeeks = (weekString: string) => {
            const [year, week] = weekString.split('-W').map(Number);
            const januaryFirst = new Date(year, 0, 1);
            const daysToAdd = (1 - januaryFirst.getDay()) + 7 * (week - 1);

            if (januaryFirst.getDay() === 0) {
                januaryFirst.setDate(2);
            } else if (januaryFirst.getDay() > 1) {
                januaryFirst.setDate(januaryFirst.getDate() + (8 - januaryFirst.getDay()));
            }
            januaryFirst.setDate(januaryFirst.getDate() + daysToAdd);

            return januaryFirst;

        }

        const generateMonthSequence = (startDate: string, format: string) => {
            let start = new Date();
            if (format == "YYYY-W") {
                start = getStartForWeeks(startDate);
            } else {
                start = new Date(startDate)
            }
            const today: Date = new Date();
            let end;


            switch (format) {
                case "YYYY":
                    end = new Date(today.getFullYear() + 1, 0, 1);
                    break;
                case "YYYY-MM":
                    end = new Date(today.getFullYear(), today.getMonth() + 1, 1);
                    break;
                case "YYYY-MM-DD":
                    end = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
                    break;
                case "YYYY-W":
                    const currentWeek = Math.floor((today.getTime() - new Date(today.getFullYear(), 0, 1).getTime()) / (7 * 24 * 60 * 60 * 1000));
                    end = new Date(today.getFullYear(), 0, 1 + currentWeek * 7);
                    break;
                default:
                    end = today;
                    break;
            }

            const result: HistoryFormat[] = [];

            while (start <= end) {
                result.push({
                    date: formatDate(start, format),
                    value: {},
                    lineVal: 0
                });

                switch (format) {
                    case "YYYY":
                        start.setFullYear(start.getFullYear() + 1);
                        break;
                    case "YYYY-MM":
                        start.setMonth(start.getMonth() + 1);
                        break;
                    case "YYYY-MM-DD":
                        start.setDate(start.getDate() + 1);
                        break;
                    case "YYYY-W":
                        start.setDate(start.getDate() + 7);
                        break;
                }
            }

            return result;
        }

       
        const min = startTick;

        const newData = generateMonthSequence(min, selectedSequence.format);

        for (let tick in histData[selectedHistory as allDisTypes]) {
            const nv = histData[selectedHistory as allDisTypes][tick];
                newData.forEach((d) => {
                    if (d.date == tick) {
                        for (let k in nv) {
                            const idx: HistType = k as HistType;
                            const nn = nv as HistGroupType;
                            if (nn !== undefined) {
                                const helper = nn[idx] || 0;
                                if (helper !== undefined)
                                    d.value[idx] = (d.value[idx] || 0) + helper;
                            }
                        }
                    }
                })
            }
        

        //calc derived Values
        let newMaxTask = 0;
        let newMaxKis = 0;

        newData.forEach((d: HistoryFormat) => {
            if (selectedHistory == "tasks") {
                const auto = (d.value["full"] || 0) + (d.value["part"] || 0);
                const all =  auto + (d.value["assist"] || 0) + (d.value["not"] || 0);
                if (all > 0) {
                    d.lineVal = (Math.round(auto / all * 100) * 0.9);
                } else {
                    d.lineVal = 0;
                }

                newMaxTask = Math.max(all, newMaxTask);
            } else {
                const all = (d.value["undeployed"] || 0) + (d.value["default"] || 0) + (d.value["validation"] || 0);
                newMaxKis = Math.max(all, newMaxKis);
            }

        });

        if (newMaxTask == 0) newMaxTask = 100;
        if (newMaxKis == 0) newMaxKis = 100;

        setMaxTask(Math.round(newMaxTask * 1.3));
        setMaxKis(Math.round(newMaxKis * 1.3));
        setInnerData(newData);

    }, [histData, selectedHistory, selectedSequence])

    const reduceArrayElements = (data: string[], maxElements: number) => {
        if (data.length <= maxElements) return data;

        const reducedArray = [];
        const step = Math.ceil((data.length) / (maxElements));

        let i = 0;
        for (i = 0; i < data.length; i += step) {
            reducedArray.push(data[i]);
        }

        if (i >= data.length) {
            reducedArray.pop();
            reducedArray.push(data[data.length - 1])
        }

        return reducedArray;
    }

    useEffect(() => {
        if (containerWidth > 0 && containerHeight > 0) {

            d3.select(d3Container.current).selectAll("*").remove();

            const svg = d3.select(d3Container.current).append('svg')
                .attr('width', containerWidth)
                .attr('height', containerHeight);

            const drawWidth = containerWidth - 10;

            const xScale = d3.scaleBand()
                .domain(innerData.map((d => d.date)))
                .range([35, drawWidth])
                .padding(0.1);

            const drawHeight = containerHeight - 10;
            const valBorder = (selectedHistory == "tasks") ? maxTask : maxKi;
            const filteredTicks = reduceArrayElements(innerData.map((d => d.date)), 13);

            const yScale = d3.scaleLinear()
                .domain([0, valBorder])
                .range([0, drawHeight]);

            const yScaleR = d3.scaleLinear()
                .domain([0, valBorder])
                .range([drawHeight, 0]);

            const yLineScale = d3.scaleLinear()
                .domain([0, 100])
                .range([0, drawHeight]);

            const yLineScaleR = d3.scaleLinear()
                .domain([0, 100])
                .range([drawHeight, 0]);

            let path:[number,number][] = [];
            
            const lineGenerator = d3.line()
                .x(d => d[0])
                .y(d => d[1])
                .curve(d3.curveBasis);
            path.push([35, drawHeight]);
            
            const drawG = svg.append("g");
            drawG.attr('transform', `translate(-10,0)`);

          

            innerData.forEach((d) => {
                const px = xScale(d.date) || 0;
                const g = drawG.append("g");

                const no = (selectedHistory == "tasks") ? (d.value["not"] || 1) : d.value["undeployed"] || 0;
                const baseClass = "historyBlock " + ((selectedHistory == "tasks") ? "tasks" : "kis")
                
                g
                    .append("rect")
                    .attr("x", px)
                    .attr("y", drawHeight - yScale(no))
                    .attr("height", yScale(no))
                    .attr("width", xScale.bandwidth())
                    .attr("class", baseClass + " first")

                const pa = (selectedHistory == "tasks") ? d.value["part"] || 0 : d.value["validation"] || 0;

                g
                    .append("rect")
                    .attr("x", px)
                    .attr("y", drawHeight - yScale(no) - yScale(pa))
                    .attr("height", yScale(pa))
                    .attr("width", xScale.bandwidth())
                    .attr("class", baseClass + " second")
                
                //Inserting new assissted state

                let as = 0;
                if (selectedHistory === "tassks") {

                    as = d.value["assist"] || 0;
                    g
                        .append("rect")
                        .attr("x", px)
                        .attr("y", drawHeight - yScale(no) - yScale(pa) - yScale(as))
                        .attr("height", yScale(as))
                        .attr("width", xScale.bandwidth())
                        .attr("class", baseClass + " fourth");
                }

                const fu = (selectedHistory == "tasks") ? d.value["full"] || 0 : d.value["default"] || 0;

                g
                    .append("rect")
                    .attr("x", px)
                    .attr("y", drawHeight - yScale(no) - yScale(pa) - yScale(fu) - yScale(as))
                    .attr("height", yScale(fu))
                    .attr("width", xScale.bandwidth())
                    .attr("class", baseClass + " third")

                path.push([(px + (xScale.bandwidth() / 2)), (drawHeight - yLineScale(d.lineVal)) ])
            });

            path.push([containerWidth, path[path.length - 1][1]]);

            drawG.append('path')
                .datum(path)
                .attr('stroke', 'rgba(255,128,128,1)')
                .attr('stroke-width', 2)
                .attr('fill', 'none')
                .attr("d", lineGenerator)

            
        }

    }, [containerWidth, containerHeight, innerData]);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    const allUnit = useState();

    if (!allUnit) {
        return <></>;
    }

    return (
        <div className="UnitBox">
            <div className="UnitBoxTitle">{unit.name}</div>

            <div className="UnitBoxContent">
                <div className="UnitSector">
                    <div>
                        
                        <div className="HistoryBarBackgroundDGAll" ref={d3Container}></div>
                    </div>
                </div>
            </div>            
        </div>
    )
})

export default HistoryUnitBox;