import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, GlobalContextProps } from "../../globalProvider";
import UnitButtonElement from "./UnitButtonElement";
import UnitTypeButton from "./UnitTypeButton";
import { defaultUnit, DGunit } from "../../dataStruct/DGUnit";
import { unitTypes } from "../../dataStruct/UnitTypes";

export type UnitButton = {
    id: number;
    name: string;
    DGunit: DGunit;
    isSelected: boolean;
    meta?: any
}

const UnitSelector: React.FC = () => {
    const [unitList, setUnitList] = useState<UnitButton[]>([]);

    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { registerEventListener, unregisterEventListener, allUnits, updateStorage } = newLocal;

    const handleActivation = (sel: number) => {
        if (sel === 0) {
            setUnitList(oldList =>
                oldList.map(unit => unit.id !== sel ? { ...unit, isSelected: false } : unit)
            )
        } else {
            setUnitList(oldList =>
                oldList.map(unit => unit.id === sel ? { ...unit, isSelected: !unit.isSelected } : unit)
            )
        }
    }

    useEffect(() => {
        //(de)activate all relative to selected units
        const noneSelected = unitList.every(unit => unit.id === 0 || !unit.isSelected);
        if (unitList.length == 0) return;
        if (unitList[0].isSelected != noneSelected) {
            setUnitList(oldList =>
                oldList.map(unit => unit.id === 0 ? { ...unit, isSelected: noneSelected } : unit)
            )
        }
        updateStorage("unitList", unitList);
    }, [unitList])

    useEffect(() => {
        const newUnitList: UnitButton[] = [];
        newUnitList.push({
            id: 0,
            name: 'Alle',
            DGunit: defaultUnit,
            isSelected: true
        });

        allUnits.forEach((unit, count) => {
            newUnitList.push({
                id: count + 1,
                name: unit.name,
                DGunit: unit,
                isSelected: false
            });
        })
        setUnitList(newUnitList)
    }, [allUnits])

    useEffect(() => {
        const l = registerEventListener("activateUnit", handleActivation)
        return () => {
            unregisterEventListener("activateUnit", l);
        }
    }, [])

    if (unitList.length == 0) {
        return(<div></div>)
     }

    return (
        <div className="unitContainer"><div className="UnitButtonWrapperText">Einheitentypen:</div>
            <div className="UnitButtonWrapper">
                {unitTypes.map((ut => {
                    return <UnitTypeButton
                        type={ut}
                        key={ut.id}
                    />
                }))}
            </div>
            <div className="UnitButtonSeperator"></div>
            <div className="UnitButtonWrapperText">Einheiten:</div>
            <div className="UnitButtonWrapper">
                {
                    unitList.map((ub => {
                        return (
                            <UnitButtonElement
                                key={ub.id}
                                ub={ub}
                            />)
                    }))
                }

            </div>
        </div>
    );
}


export default UnitSelector;
