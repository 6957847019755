import * as d3 from 'd3';
import { useContext, useRef, useState, useEffect } from "react";
import { GlobalContext, GlobalContextProps } from "../../../globalProvider";
import { svgBox } from './svgBox';
import { maxList, waterfallBar, waterfallStruct } from '../KnowledgeTransfer';
import './Knowledge.scss';
import { DGunit } from '../../../dataStruct/DGUnit';

export type DGAllKnowledgeBoxProps = {
    data: DGunit,
    maxList: maxList
}

export type containerData = {
    width: number,
    height: number,
}

export const DGAllKnowledgeBox: React.FC<DGAllKnowledgeBoxProps> = ({ data, maxList }) => {
    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { storage } = newLocal;

    const d3Container = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [svg, setSvg] = useState<d3.Selection<any, any, any, any> | null>(null)
    const [dgWaterfall, setdgWaterfall] = useState<waterfallBar[]>([]);

    useEffect(() => { 
        // Main
        const nData: waterfallBar[] = [];
        waterfallStruct.forEach((ele, k) => {
            
            nData.push({
                id: ele.id,
                label: ele.label,
                maxVal: maxList[ele.id],
                value: ele.dataCreator(data),
                flowConnectors: []
            })
        })

        // calculate connectors
        waterfallStruct.forEach((ele, k) => {
            const myBar = nData.find(obj => obj.id === ele.id);
            if (myBar) {
                ele.connect.forEach((con, ck) => {
                    const myConn = nData.find(obj => obj.id === con.toId);
                    if (myConn) {
                        let mark = false; 
                        if (con.markSufficientBorder) {
                            mark = con.markSufficientBorder(myBar, myConn)
                        }
                        myBar.flowConnectors.push({
                            label: con.dataCreator(myBar, myConn),
                            start: 0,
                            markSufficientBorder: mark,
                            end: myConn.value,
                            poststart: 0,
                            postend: myConn.value,
                            postmax: myConn.maxVal
                        })
                    }
                })
            }
        });
       
        setdgWaterfall(nData)
    }, [data])
    
    useEffect(() => {
        dgWaterfall.map((v, k) => { 
            return svgBox({
                root: svg,
                data: v,
                container: {
                    height: containerHeight,
                    width: containerWidth
                },
                count: k
            })

        })
        
    }, [svg])

    useEffect(() => {
        let isMounted = true;
        if (d3Container.current) {
            
            const resizeObserver = new ResizeObserver(entries => {
                
                for (let entry of entries) {
                    window.requestAnimationFrame(() => {
                        if (isMounted) {
                               
                            setContainerWidth(entry.contentRect.width +1);
                            setContainerHeight(entry.contentRect.height +1);
                        }
                    });

                }
            });

            resizeObserver.observe(d3Container.current);
            return () => {
                isMounted = false;
                resizeObserver.disconnect();
            };
        } 
    }, [d3Container]);


    useEffect(() => {

        if (containerWidth > 0 && containerHeight > 0) {
            d3.select(d3Container.current).selectAll("*").remove();

            const mysvg = d3.select(d3Container.current).append('svg')
                .attr('width', containerWidth)
                .attr('height', containerHeight)
                .attr('class', 'knowledgeSVGBox')


            // define shadows and gradients
            const defs = mysvg.append('defs');

            const linearGradient = defs.append('linearGradient')
                .attr('id', 'bg-gradient')
                .attr('x1', '0.422')
                .attr('y1', '0.403')
                .attr('x2', '0.422')
                .attr('gradientUnits', "objectBoundingBox");

            linearGradient.append('stop')
                .attr('class','gradientStop1')
                
            linearGradient.append('stop')
                .attr('offset', '100%')
                .attr('class', 'gradientStop2')

            const shadowFilter = defs.append('filter')
                .attr('id', 'drop-shadow')
                .attr('height', '130%');

            shadowFilter.append('feDropShadow')
                .attr('dx', 1)
                .attr('dy', 1)
                .attr('stdDeviation', 2)
                .attr('flood-color', '#888888');

            setSvg(mysvg);
        } 
    }, [containerWidth, containerHeight, dgWaterfall]);

    const conf = storage.get("config");

    return (
        <div className="DGAllBox DGAllBoxVertical">
            <div className="DGAllBoxTitle">{conf.HIRO_COMPANY_FULL_NAME}</div>
            <div className="DGAllBoxContent">
                <div className="DGHumanAllSector DGHumanAllSectorVertical">
                    <div className="UnitWaterfallBackground" ref={d3Container}>
                       
                    </div>

                </div>
            </div>
        </div>);
};

export default DGAllKnowledgeBox;
