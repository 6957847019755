import { useContext } from "react";
import { GlobalContextProps, GlobalContext } from "../../globalProvider";
import './GraphContainer.scss';

const GraphContainer = () => {
    const newLocal: GlobalContextProps = useContext(GlobalContext) as GlobalContextProps;
    const { selectedModule, sendMessage } = newLocal;
    const MyModule: React.FC = selectedModule.hook;
    
    const handleClick = () => {
        sendMessage("clickInMain","")
    }

    return (<div className="graphContainer" onClick={handleClick}>
        <MyModule />
    </div>)
}

export default GraphContainer;